import React from 'react';
import { atualizaMeusDados } from './actions'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';


export class fetchMeusDados extends React.Component {


    componentDidMount() {
        this.fetchMeusDados(this.props.evento.id, this.props.user.token);
    }
    componentDidUpdate(prevProps) {
        if ((this.props.evento !== prevProps.evento) ||
            (this.props.user !== prevProps.user)) {
            this.fetchMeusDados(this.props.evento.id, prevProps.user.token);
        }
    }

    fetchMeusDados = async (eventoId,token) => {
        if ((!eventoId) || (!token)) {
            return;
        }
        

        this.props.atualizaMeusDados(eventoId, token);
    }

    render() {
        return null;
    }
}


const mapStateToProps = store => ({
    evento: store.eventoState.evento.item,
    user: store.userState.user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ atualizaMeusDados }, dispatch);



export default connect(mapStateToProps,mapDispatchToProps)(fetchMeusDados);