import { useState, useEffect, useRef, useCallback } from 'react';

const host = 'https://datetime.api.pandora.com.br';

function useNow() {
    const [initialized, setInitialized] = useState(false);
    const datetime = useRef(new Date('2000-01-01'));

    useEffect(() => {
        fetch(host, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            }
        }).then((response) => response.json()).then(response => {
            datetime.current = new Date(response.now);
            setInitialized(true);
        });
    }, []);

    useEffect(() => {
        if (initialized) {
            const interval = setInterval(() => {
                datetime.current = new Date(datetime.current.getTime() + 1000);
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [initialized])


    const now = useCallback(() => {
        return datetime.current;
    },[])

 
    return now;
}

export default useNow;