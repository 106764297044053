import React from 'react';



function Instrucoes() {

    return (
        <>
            <section>
                <div className="row">
                    <div className="col-12">
                        <div className="espaco-60" style={{height: "60px"}}></div>
                        <h1 className="text-primary font-weight-bold">Para participar da Assembleia</h1>
                        <h2 className="font-weight-bold">Você estará participando de uma assembleia formato Palco-Plateia (webinar).</h2>
                        <div className="espaco-60" style={{height: "60px"}}></div>
                    </div>
                </div>
            </section>
            <div className="row">
                <div id="timeline" className="d-flex flex-wrap w-100 bg-primary" style={{height: "5px"}}></div>
                <div className="col-xl-3 col-12" style={{margin: "0", padding: "0 3%"}}>
                    <section className="text-center">
                        <div className="linha-subitem bg-primary" style={{width: "5px", height: "60px", margin: "0 auto"}}></div>
                        <div className="bullet bg-primary" style={{height: "16px", width: "16px", borderRadius: "50%", margin: "0 auto"}}></div>
                        <div className="espaco-20" style={{height: "20px"}}></div>
                        <h2 className="text-primary font-weight-bold">1 - ENTRAR NA ASSEMBLEIA</h2>
                        <div className="espaco-20" style={{height: "20px"}}></div>
                        <p className="text-dark h5 font-weight-bold">
                            Ao entrar na assembleia virtual você visualizará e escutará apenas o anfitrião da assembleia, no caso,
                            a mesa diretora composta por um ou mais membros da diretoria ou convidados.
                </p>
                    </section>
                </div>
                <div className="col-xl-3 col-12" style={{margin: "0", padding: "0 3%"}}>
                    <section className="text-center">
                    <div className="linha-subitem bg-primary" style={{width: "5px", height: "60px", margin: "0 auto"}}></div>
                        <div className="bullet bg-primary" style={{height: "16px", width: "16px", borderRadius: "50%", margin: "0 auto"}}></div>
                        <div className="espaco-20" style={{height: "20px"}}></div>
                <h2 className="text-primary font-weight-bold">2 - PARA PARTICIPAR</h2>
                        <div className="espaco-20" style={{height:"20px"}}></div>
                        <p className="text-dark h5 font-weight-bold">
                            Como numa assembleia presencial, você terá direito a voz e poderá se manifestar. A inscrição poderá ser realizado pelo recurso de bate papo ou de levantar a mão,
                            mas que se ocorrerá quando a mesa diretora abrir para inscrições.
                </p>
                    </section>
                </div>
                <div className="col-xl-3 col-12" style={{margin: "0", padding: "0 3%"}}>
                    <section className="text-center">
                        <div className="linha-subitem bg-primary" style={{width: "5px", height: "60px", margin: "0 auto"}}></div>
                        <div className="bullet bg-primary" style={{height: "16px", width: "16px", borderRadius: "50%", margin: "0 auto"}}></div>
                        <div className="espaco-20" style={{height: "20px"}}></div>
                        <h2 className="text-primary font-weight-bold">3 - MANIFESTAR</h2>
                        <div className="espaco-20" style={{height: "20px"}}></div>
                        <p className="text-dark h5 font-weight-bold">
                            Após a aprovação da sua inscrição você será transportado à sala virtual na condição de apresentador,
                            onde os demais participantes poderão acompanhar a sua fala. Ao término da fala você retornará a condição de participante.
                </p>
                    </section>
                </div>
                <div className="col-xl-3 col-12" style={{margin: "0", padding: "0 3% 0 3%"}}>
                    <section className="text-center">
                        <div className="linha-subitem bg-primary" style={{width: "5px", height: "60px", margin: "0 auto"}}></div>
                        <div className="bullet bg-primary" style={{height: "16px", width: "16px", borderRadius: "50%", margin: "0 auto"}}></div>
                        <div className="espaco-20" style={{height: "20px"}}></div>
                        <h2 className="text-primary font-weight-bold">4 - VOTAR</h2>
                        <div className="espaco-20" style={{height: "20px"}}></div>
                        <p className="text-dark h5 font-weight-bold">
                            Durante a assembleia poderá ocorrer votação de algum tema. Fique atento para as explicações.
                </p>
                    </section>
                </div>
            </div>
        </>
    )
}


export default Instrucoes;