import React from 'react';
import { withRouter } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormAlert from '../components/FormAlert';
import { MOTIVO_2FA, MOTIVO_NAO_ECONTRADO, MOTIVO_NAO_APROVADO, MOTIVO_EVENTO_FECHADO, MOTIVO_NAO_APROVADO_SMS, MOTIVO_QUESTIONARIO} from '.';
import { connect } from 'react-redux';
import { DigitaAprovacaoTokenModal } from '../components/DigitaAprovacaoTokenModal';
import votante from '../fetchs/votante';
import NaoAprovado from './NaoAprovado';


export class LoginForm extends React.Component {

  state = {
    loading: false,
    abre2fa: false,
    abreDigitaToken: false,
    votanteId: null,
    showNaoAprovado: false,
  };

  data = {};
  alert = null;
  urlRedirect = null;

  handleFechou2fa = () => {
    this.setState({ abre2fa: false });
  }


  handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.currentTarget;

    if ((form.checkValidity() === false) || (!this.data.valid)) {
      this.alert.show("Dados inválidos");

      return;
    }

    this.setState({
      loading: true
    });

    const evento = this.props.evento;

    const autentica = await votante.fetchAutentica(evento, this.data.data);

    this.setState({
      loading: false
    });

    switch (autentica.status) {
      case 401:
        this.urlRedirect = autentica.data.redirect;
        switch (autentica.data.motivo) {
          case MOTIVO_QUESTIONARIO:
            this.handleOpenQuestionario(this.data.data);
            break;          
          case MOTIVO_2FA:
            this.setState({ abre2fa: true })
            break;
          case MOTIVO_NAO_APROVADO_SMS:
            this.setState({ 
              abreDigitaToken: true,
              votanteId: autentica.data.votanteId 
            })
            break;
          case MOTIVO_NAO_ECONTRADO:
              this.handleNaoEncontrado();
            break;
          case MOTIVO_EVENTO_FECHADO:
            this.handleMsgErro(autentica.data.mensagem);
            break;
          case MOTIVO_NAO_APROVADO:
            this.setState({showNaoAprovado:true});
            break;
          default:
            this.handleMsgErro(autentica.data.mensagem);
            break;
        }
        break;
      case 404:
        this.handleMsgErro(autentica.data.mensagem);
        break;
      case 200:
        this.login(autentica.data.nome, autentica.data.tokenTemporario);
        break;
      default:
        alert('Ops.. Erro ao efetuar a autenticação, tente novamente mais tarde');
        break;
    }

  };

  handleOpenQuestionario = (data) => {
    this.props.openQuestionario(data);
  }
  
  login = (nome,token) => {
    this.props.onLogin(nome, token);
  }

  fechaDigitaToken = () => {
    this.setState({ abreDigitaToken: false })
  }
  handleChange = ({valid},data) => {
    this.data.valid = valid;
    this.data.data = data;
  }

  handleNaoEncontrado = () => {
    this.handleFechou2fa();
    this.alert.show('Desculpe. De acordo com os dados apresentandos, você não está apto a votar neste evento.');
    this.props.onNaoEncontrado({redirect:this.urlRedirect});
  }

  handleMsgErro = (mensagem) => {
    this.alert.show(mensagem);
  }

  cadastrarClick = () => {
    this.props.cadastrarClick();
  }

  render() {
    const {
      className
    } = this.props;
    if (this.state.showNaoAprovado) {
      return (
        <NaoAprovado onClose={()=>this.setState({showNaoAprovado:false})}/>
      );
    }

    return (
      <>
        <DigitaAprovacaoTokenModal 
          isOpen={this.state.abreDigitaToken} 
          onHide={this.fechaDigitaToken} 
          votanteId={this.state.votanteId}
          evento={this.props.evento}
          login={this.login}
          incluirParticipanteHandle={this.cadastrarClick}
        />
        <Form noValidate onSubmit={this.handleSubmit}
          className={`login-form ${className}`} id='login-body-card-container'>
            <div id='login-body-card-container-header'>
              <h6>Faça login para Participar</h6>
            </div>
          {this.props.render(this.handleChange, this.handleFechou2fa, this.state.abre2fa,this.handleNaoEncontrado)}

          <FormAlert ref={(ref) => this.alert = ref} />

          <Button size="lg" variant="success" type="submit" block disabled={this.state.loading}>
            {this.state.loading ? 'Autenticando…' : 'Entrar'}
          </Button>
          {this.props.evento.podeCadastrarParticipante &&
              <div className="mt-1">
                <div>Não se cadastrou?{' '}
                <Button variant="link" className="p-0 border-0" onClick={this.cadastrarClick}>Cadastre-se!</Button></div>
              </div>
          }
        </Form>
      </>
    );

  }
}



const mapStateToProps = store => ({
  evento: store.eventoState.evento.item
});



export default connect(mapStateToProps)(withRouter(LoginForm));


