import React from 'react'
import Navbar from 'react-bootstrap/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import parse from 'html-react-parser';
import ProgressBar from 'react-bootstrap/ProgressBar';


class PerguntaDescricao extends React.Component {
    state = {
        disablePrev: true,
        disableNext: true
    }

    componentDidMount() {
        this.setDisablePrevNext();
    }

    componentDidUpdate(prevProps) {
        if ((this.props.perguntas !== prevProps.perguntas) ||
            (this.props.selecionada !== prevProps.selecionada)) 
        {
            this.setDisablePrevNext();
        }

     

    }

    getIndexSelecionado = () => {
        return this.props.perguntas.indexOf(this.props.selecionada);
    }
    setDisablePrevNext = () => {
        const indexSelecionado = this.getIndexSelecionado();

        this.setState(
            {
                disablePrev: (indexSelecionado <= 0),
                disableNext: (indexSelecionado >= this.props.perguntas.length-1)
            }
        );
    }


    handlePrevClick = () => {
        this.props.onSelecionaPergunta(
            this.props.perguntas[
                this.props.perguntas.indexOf(this.props.selecionada)-1
            ]
        );
    }

    handleNextClick = () => {
        this.props.onSelecionaPergunta(
            this.props.perguntas[
                this.props.perguntas.indexOf(this.props.selecionada)+1
            ]
        );
        
    }
    render() {
        return (
            <div>
                <Navbar className="p-0 navbar-2 border-bottom justify-content-between">
                    <Button variant="link" disabled={this.state.disablePrev} className="rounded-0 border-right" onClick={this.handlePrevClick}>
                        <FontAwesomeIcon size="lg" icon={faArrowLeft} />
                    </Button>
                    <Navbar.Brand>PERGUNTA</Navbar.Brand>
                
                    <Button variant="link" disabled={this.state.disableNext} className="rounded-0 border-left" onClick={this.handleNextClick}>
                        <FontAwesomeIcon size="lg" icon={faArrowRight} />
                    </Button>

                </Navbar>

                <ProgressBar variant="info" animated now={(this.props.votos.length / this.props.perguntas.length)*100}
                    label={`${this.props.votos.length} / ${this.props.perguntas.length}`} />
                <Container>
                    <h3>
                        {this.props.selecionada.titulo && this.props.selecionada.titulo}
                        
                        {this.props.selecionada.nMaxRespostas > 1 && 
                        (<>{' '}<small className="text-muted">(Máx: {this.props.selecionada.nMaxRespostas})</small></>)
                        }
                    </h3>
                    {this.props.selecionada.descricaoHtml &&
                    parse(this.props.selecionada.descricaoHtml)}

                </Container>

            </div>
        )
    }
}


export default PerguntaDescricao;