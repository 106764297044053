import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logoutUser } from './actions';
import Button from 'react-bootstrap/Button';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter} from 'react-router-dom';
import { getBase } from './components/RouterPanagora';

export function UsuarioInfo(props) {
  if (props.user) {
    return (
      <>
        Olá, <span className="font-weight-bold">{props.user.nome}</span>
      </>
    );
  }
  return null;
}

export function Cabecalho(props) {

  let handleSairClick = (e) => {
    e.preventDefault();
    props.history.push(getBase()+'/auth');
    props.logoutUser();
  };
  
  let handleBrandClick = (e) => {
    e.preventDefault();
    props.history.push(getBase());
  }


  return (
    <Navbar bg="primary" className="navbar-superior justify-content-between" variant="dark">
      <Navbar.Brand className="mr-auto w-75 overflow-hidden" href="#" onClick={handleBrandClick} >
        <span className={`d-inline-block ${props.evento.isLoading ? "blur-text" : ""}`}>
          {props.evento.isLoading ? 'Assembleia do Sindicato' : props.evento.item.nome}
        </span>
      </Navbar.Brand>

      <Navbar.Brand className="d-none d-sm-block">
        <Navbar.Text>
          <UsuarioInfo user={props.user} />
        </Navbar.Text>
      </Navbar.Brand>
      <Button onClick={handleSairClick}>
        <FontAwesomeIcon size="lg" icon={faSignOutAlt} />
      </Button>

    </Navbar>

  );
}

const mapStateToProps = store => ({
  evento: store.eventoState.evento,
  user: store.userState.user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ logoutUser }, dispatch);


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cabecalho));