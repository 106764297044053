import React, { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import urna from "../../fetchs/urna";


function SelecionaUrna({ evento, onSelecionou, onCancelar }) {
    const [loading, setLoading] = useState(true);
    const [urnas, setUrnas] = useState([])
    const [validated, setValidated] = useState(false);
    const [urnaSelect, setUrnaSelect] = useState('');

    useEffect(() => {
        if (!evento.id) return;

        const fetchUrnas = async () => {
            setLoading(true);
            const urnaReturn = await urna.fetchList(evento.id,'alteracao');
            setLoading(false);
            if (urnaReturn.isSuccess()) {
                setUrnas(urnaReturn.getData())
            } else {
                console.error(urnaReturn);
                alert('Ops.. Ocorreu um erro ao pegar as urnas. Tente novamente mais tarde');
            }
        }
        fetchUrnas();

    }, [evento.id])


    const handleSubmit = async (event) => {
        event.preventDefault();

        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            setValidated(true);
            return;
        }
        onSelecionou(urnas.find(urna => (urna.id == urnaSelect)))

    }
    const handleChange = (event) => {
        setUrnaSelect(event.target.value);
    }


    return (
        <>
            <Modal.Body>
                <Alert variant="info">
                    Ainda falta uma informação para que você possa realizar seu voto,
                    complete a informação a baixo para continuar
                </Alert>
                {loading ?
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Carregando...</span>
                    </Spinner>
                    :
                    <Form noValidate validated={validated} onSubmit={handleSubmit} id="formSelecionaUrnaModal">
                        <Form.Group>
                            <Form.Label>{evento.tituloUrna}</Form.Label>
                            <Form.Control as="select" name="urna" required={true} custom
                                onChange={handleChange} value={urnaSelect}>
                                <option value={null}></option>
                                {urnas.map((urna) => (<option key={urna.id} value={urna.id}>{urna.descricao}</option>))}
                            </Form.Control>
                        </Form.Group>
                    </Form>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onCancelar} className="mx-1">
                    Cancelar
                </Button>
                <Button variant="success" form="formSelecionaUrnaModal" type="submit" className="mx-1">
                    Continuar
                </Button>
            </Modal.Footer>
        </>)
}

export default SelecionaUrna;