import React from 'react';
import webinar from '../fetchs/webinar';
import { Container, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getBase } from '../components/RouterPanagora';
import LinkSala from './LinkSala';
import Panagora from './Panagora';


export class WebinarIndex extends React.Component {

    state = {
        webinar: null
    }

    componentDidMount = async () => {

        this.entrarWebinar(this.props.evento.item.id);
    }

    componentDidUpdate(prevProps) {
        if ((this.props.evento.item !== prevProps.evento.item) ||
            (this.props.user !== prevProps.user)) {
            this.entrarWebinar(this.props.evento.item.id);
        }
    }

    entrarWebinar = async (idEvento) => {
        if (isNaN(idEvento))
            return;

        const dados = await webinar.entrar({ idEvento: idEvento });
        if (!dados.success) {
            if (dados.data.mensagem) {
                alert(dados.data.mensagem);
            }
            this.props.history.push(getBase());
        } else {
            this.setState({ webinar: dados.data });
        }
    }


    render() {
        if (this.state.webinar === null) {
            return (
                <Container className="d-flex justify-content-center">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Carregando...</span>
                    </Spinner>
                </Container>
            );

        }

        switch (this.state.webinar.tipo) {
            case 'zoom-webinar-api':
            case 'zoom-meeting-api':
            case 'link-sala':
                return <LinkSala
                    link={this.state.webinar.dados.link}
                />
            case 'panagora':
                return <Panagora
                    token={this.state.webinar.dados.token}
                    urlPublisher={this.state.webinar.dados.urlPublish}
                    urlPlayer={this.state.webinar.dados.urlPlay}
                    urlApi={this.state.webinar.dados.urlApi}
                />

            default:
                return <p>Tipo de webinar não esperado {this.state.webinar.tipo}</p>
        }
    }
}


const mapStateToProps = store => ({
    evento: store.eventoState.evento,
    user: store.userState.user,
});


export default withRouter(connect(mapStateToProps)(WebinarIndex));
