import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Jumbotron from 'react-bootstrap/Jumbotron';
import { Redirect } from './RouterPanagora';


export default function InformacaoContinuar({titulo,children,continuarClick,continuarHref}) {

    const [redirect,setRedirect] = useState(null);

    function continuarHandler() {
        if (continuarHref) {
            setRedirect(continuarHref);
        } else if (continuarClick) {
            continuarClick();
        }
    }

    if (redirect !== null) {
        return (
            <Redirect to={redirect} />
        )
    }


    return (
        <Jumbotron>
            <h1>{titulo}</h1>
            <p>
                {children}
            </p>
            <p>
                <Button variant="primary" onClick={continuarHandler}>Continuar</Button>
            </p>
        </Jumbotron>
    )
}