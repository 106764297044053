import React from 'react';
import Button from 'react-bootstrap/Button';


export default function NovoCadastroLink({ incluirParticipanteHandle }) {
  return <small>
    <div><Button variant="link" className="p-0 border-0" size="sm"
      onClick={incluirParticipanteHandle} >
      Clique aqui para fazer um cadastro com um número diferente!
    </Button></div>
  </small>
}