import React from 'react'
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCheckDouble } from '@fortawesome/free-solid-svg-icons'

class BotaoVotar extends React.Component {

    state = {
        temProximo: true,
        loading: false
    }

    componentDidMount() {
        this.setTemProximo();
    }

    componentDidUpdate(prevProps) {
        if ((this.props.selecionada !== prevProps.selecionada) ||
            (this.props.perguntas !== prevProps.perguntas)) {
            this.setTemProximo();
        }
    }



    setTemProximo = () => {
        const indexSelecionado = this.getIndexSelecionado();
        this.setState(
            {
                temProximo: (indexSelecionado < this.props.perguntas.length-1)
            }
        );

    }

    getIndexSelecionado = () => {
        return this.props.perguntas.indexOf(this.props.selecionada);
    }


    click = () => {
        if (this.state.temProximo) {
            this.props.onSelecionaPergunta(
                this.props.perguntas[
                    this.props.perguntas.indexOf(this.props.selecionada)+1
                ]
            );
        } else {
            this.props.onConfirmarVoto();
        }
    }

    jaVotou = () => {
        return (
            this.props.votos.find((voto)=> voto.perguntaId===this.props.selecionada.id) !==
            undefined
        );
    }

    render() {
        return (
            <Button onClick={this.click} size="lg" variant="success" type="submit" block 
                disabled={(!this.jaVotou()) || (this.props.disabled)}>
                {this.state.loading ? 'Registrando...' :
                    this.state.temProximo ? 
                        <>Próximo <FontAwesomeIcon className="float-right my-1" icon={faArrowRight}/></> :
                        <>Confirmar <FontAwesomeIcon className="float-right my-1" icon={faCheckDouble}/></>
                }
            </Button>
        )
    }
}


export default BotaoVotar;