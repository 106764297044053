import React from 'react';
import { Switch, Route } from 'react-router-dom';
import EventoIndex from './evento';
import VotacaoIndex from './votacao';
import Cabecalho from './Cabecalho';
import Rodape from './Rodape';
import Comprovante from './votacao/Comprovante';
import Termo from './auth/Termo';
import VerificaTermo from './auth/VerificaTermo';
import FetchMeusDados from './FetchMeusDados';
import { Redirect } from './components/RouterPanagora';
import WebinarIndex from './webinar';
import GeraTokenContainer from './auth/GeraTokenContainer';


export default function PrivateLayout() {
    return (
        <>
            <Cabecalho />

            <div className="conteudo-private h-100">
                <GeraTokenContainer>
                    <Switch>
                        <Route exact path={["/:eventoUrl/votar", "/:sindicatoUrl/:eventoUrl/votar"]} component={VotacaoIndex} />
                        <Route exact path={["/:eventoUrl/comprovante", "/:sindicatoUrl/:eventoUrl/comprovante"]} component={Comprovante} />
                        <Route exact path={["/:eventoUrl/termo", "/:sindicatoUrl/:eventoUrl/termo"]} component={Termo} />
                        <Route exact path={["/:eventoUrl/webinar", "/:sindicatoUrl/:eventoUrl/webinar"]} component={WebinarIndex} />
                        <Route exact path={["/:eventoUrl", "/:sindicatoUrl/:eventoUrl"]} component={EventoIndex} />
                        <Redirect to="/" />
                    </Switch>
                </GeraTokenContainer>
            </div>
            <Rodape />
            <VerificaTermo />
            <FetchMeusDados />
        </>
    );
}