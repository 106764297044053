import { REQUEST_EVENTO,RECEIVE_EVENTO,EVENTO_NAO_ENCONTRADO } from '../actions/actionTypes';

const initialState = {evento:{isLoading:false,item:{},error:null}};

export const evento = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_EVENTO:
      return {
        ...state,
        evento: {isLoading: true,item:{},error:null}
      };
    case EVENTO_NAO_ENCONTRADO:
        return {
          ...state,
          evento: {isLoading: false,item:{},error:"Evento não encontrado"}
        };      
    case RECEIVE_EVENTO:
      return {
        ...state,
        evento: {isLoading: false, item: action.evento,error:null},
      };
    default:
      return state;
  }
};

