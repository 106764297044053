import React from 'react';
import { Container } from 'react-bootstrap';
import InformacaoContinuar from '../components/InformacaoContinuar';


function NaoAprovado({onClose}) {

    return (
        <Container className="mt-2">
            <InformacaoContinuar
                titulo="Não foi possível entrar no evento"
                continuarClick={onClose}
            >
                Sua participação ainda não foi aprovada, tente novamente mais tarde ou entre em contato com a entidade
            </InformacaoContinuar>

        </Container>
    )
}



export default NaoAprovado;