import { evento } from './Evento';
import { user } from './User';
import { meusDados } from './MeusDados';
import { combineReducers } from 'redux';


export const Reducers = combineReducers({
  eventoState: evento,
  userState: user,
  meusDadosState: meusDados
});