import React from 'react';
import Form from 'react-bootstrap/Form';
import style from './Questionario.module.css';

function Resposta(props) {
    return (
    <Form.Check
        custom
        className={'questionario-respostas '+ style.check}
        type="radio"
        label={props.children}
        name={`pergunta-${props.perguntaId}`}
        id={props.id}
        required
        checked={props.checked}
        onChange={props.onChange}
    />);
}

function Pergunta(props) {
    function responder(resposta) {
        props.onChange(resposta)        
    }

    return (
        <div className="questionario-pergunta mb-1">
            <h5 className="mb-3">{props.titulo}</h5>
            {props.respostas.map((resposta,i)=>(
                <Resposta 
                    checked={props.respondida === resposta}
                    onChange={()=>responder(resposta)}
                    key={i} 
                    perguntaId={props.id} 
                    id={`pergunta-${props.id}-item-${i}`}>
                    {resposta}
                </Resposta>
            ))}
            <hr/>           
        </div>
    );
}

export default Pergunta;