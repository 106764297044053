import React from 'react';
import { Redirect } from '../components/RouterPanagora';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

export class VerificaTermo extends React.Component {

    state = {
        redirectTermo: false,
        key:0
    };

    componentDidMount() {
        this.verificaTermo(this.props.evento,this.props.meusDados);
    }


    componentDidUpdate(prevProps) {
        if ((this.props.evento !== prevProps.evento) ||
            (this.props.meusDados !== prevProps.meusDados) || 
            (this.props.location.pathname !== prevProps.location.pathname)) {
            this.verificaTermo(this.props.evento,this.props.meusDados);
        }
    }


    verificaTermo = async (evento, meusDados) => {
        if ((Object.keys(meusDados).length === 0) || 
            (Object.keys(evento).length === 0)) {
            return;
        }
        if (!evento.temTermo) {
            return;
        }
        
        if (!meusDados.concordouTermo) {
        
            this.setState({ 
                redirectTermo: true,
                key: this.state.key+1
            });
        } else {
            this.setState({ 
                redirectTermo: false,
            });
        }
    }

    render() {
        if (this.state.redirectTermo) {
            return <Redirect to='/termo' key={this.state.key} />
        }
        return null;
    }
}


const mapStateToProps = store => ({
    meusDados: store.meusDadosState.meusDados,
    evento: store.eventoState.evento.item,
});



export default connect(mapStateToProps)(withRouter(VerificaTermo));