
import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import ReactInputMask from 'react-input-mask';
import Form from 'react-bootstrap/Form';
import Countdown from 'react-countdown';
import FormAlert from './FormAlert';
import Button from 'react-bootstrap/Button';


export default class DigitaToken extends React.Component {

  state = {
    loading: false,
    token: ''
  };

  alert = null;
  dateEnviou = null;

  constructor(props) {
    super(props);
    this.dateEnviou = Date.now();
  }

  componentDidUpdate(prevProps) {
    if ((this.props.confirmou) &&
      (this.props.confirmou !== prevProps.confirmou)) {
      this.handleSubmit();
    }
  }


  handleChange = async (event) => {
    let fieldVal = event.target.value;
    await this.setState({ token: fieldVal });
    if (fieldVal.replace('_', '').length === 4) {
      this.handleSubmit();
    }
  }

  handleSubmit = async () => {
    this.props.onConfirmou();

    const evento = this.props.evento;
    this.setState({ loading: true });

    const autentica = await this.props.fetchSubmit(evento, 
      { ...this.props.params, token: this.state.token,votanteId:this.props.opcao.votanteId }
    );

    this.setState({ loading: false });
    if ((autentica.status === 200) || (autentica.status === 201)) {
      this.props.login(autentica.data.nome, autentica.data.tokenTemporario);
    } else {
      if (autentica.data['mensagem']) {
        this.alert.show(autentica.data.mensagem);
      } else {
        alert('Ops.. Erro ao autenticar, tente novamente mais tarde');
        console.error(autentica.data);
      }
    }

  }

  reenviarClick = (event) => {
    event.preventDefault();
    this.props.reenviarToken();
  }

  render() {

    if (this.state.loading) {
      return (
        <Spinner animation="border" role="status">
          <span className="sr-only">Carregando...</span>
        </Spinner>
      );
    }

    const naoRecebeuRenderer = (
      <small>
        <div>Não recebeu o código?</div>
        <div><Button variant="link" className="p-0 border-0" size="sm" onClick={this.reenviarClick}>Clique aqui para reenviar!</Button></div>
      </small>
    );

    const rendererCountDown = ({ minutes, seconds, completed }) => {
      if (completed) {
        return naoRecebeuRenderer;
      } else {
        return (
          <small className="text-danger">
            Validade do código:{' '}
            <span className="font-weight-bold">
              {minutes + ' minutos e ' + seconds + ' segundos'}
            </span>
          </small>
        )
      }
    };
    return (
      <>
        <h6 className="font-weight-bold">Digite abaixo o código recebido em {this.props.opcao.valor}</h6>
        <ReactInputMask className="text-uppercase mb-3" mask="****" alwaysShowMask value={this.state.token} onChange={this.handleChange}>
          <Form.Control autoComplete="off" size="lg" name="token" required autoFocus />
        </ReactInputMask>
        
        {this.props.mostraContagem ?
        <Countdown date={this.dateEnviou + 600000} renderer={rendererCountDown} /> :
        naoRecebeuRenderer }

        <FormAlert ref={(ref) => this.alert = ref} />
      </>
    )

  }
}
