import React from 'react';
import Container from 'react-bootstrap/Container';

function NotFound() {

  setTimeout(() => {
    window.location.href="http://www.panagora.com.br";
  },500);


  return (
        <Container className="text-center mb-5">
          <h1 className="mt-5">Evento não encontrado</h1>
          <h6>Aguarde, que você será redirecionado</h6>
        </Container>
  );
}

export default NotFound;
