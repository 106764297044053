import React from 'react';
import Form from 'react-bootstrap/Form';
import DataNascimento from './form-elements/DataNascimento';
import moment from 'moment';


class MatriculaDataNascimento extends React.Component {


  state = {
    form: {matriculaFuncional: '', dataNascimento: ''}
  }


  handleChange = async (event) => {
    let fieldName = event.target.name;
    let fleldVal = event.target.value;
    await this.setState({form: {...this.state.form, [fieldName]: fleldVal}})
  

    let dataNascimento = null;
    if (moment(this.state.form.dataNascimento,'DD/MM/YYYY',true).isValid()) {
      dataNascimento = moment(this.state.form.dataNascimento,'DD/MM/YYYY').format('YYYY-MM-DD');
    }  

    this.props.handleFormChange(
      {
        valid:this.valid()
      },
      {
        matriculaFuncional: this.state.form.matriculaFuncional,
        dataNascimento: dataNascimento
      });
  }

  valid = () => {
    return (this.dataNascimento.valid());
  }

  render() {
    return (
      <>
        <Form.Group controlId="formMatriculaFuncional">
          <Form.Label>{this.props.tituloMatricula}</Form.Label>
          <Form.Control autoComplete="off" name="matriculaFuncional" required size="lg" onChange={this.handleChange} value={this.state.form.matriculaFuncional} placeholder={this.props.tituloMatricula}/>
        </Form.Group>
    
        <DataNascimento value={this.state.form.dataNascimento} onChange={this.handleChange} ref={(ref)=> this.dataNascimento = ref} required />

      </>
    );    
  }
}

export default MatriculaDataNascimento;
