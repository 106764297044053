class ResponseModel {
    status : number;

    data : any;


    constructor() {
        this.data = {};
        this.status = 0;
    }

    init = async (fetchReturn : Response) => {
        this.status = fetchReturn.status;
        try {
            this.data = await fetchReturn.json();
        } catch {
            this.data = {};
        }
        return this;
    }

    isSuccess = () => {
        switch (this.status) {
            case 200:
            case 201:
            case 204:
                return true;
            default:
                return false;
        }
    }

    getStatus = () => {
        return this.status
    }

    getData() {
        return this.data;
    }
}

export default ResponseModel;
