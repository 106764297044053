import React, { useRef } from 'react';
import WebcamInterna from "react-webcam";
import Styles from './Webcam.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';


const videoConstraints = {
    width: { ideal: 1280 },
    height: { ideal: 720 },
    facingMode: "user"
};

function Webcam(props) {
    const webcamRef = useRef(null);

    function capture() {
        const imageSrc = webcamRef.current.getScreenshot();
        props.onCapture(imageSrc);
    }

    return (
        <div className={Styles.wrapperWebcam}>
            <div className={Styles.container}>
                <WebcamInterna
                    ref={webcamRef}
                    screenshotQuality={1}
                    imageSmoothing={false}
                    videoConstraints={videoConstraints}
                    forceScreenshotSourceSize
                    audio={false}
                    screenshotFormat="image/jpeg"
                />
                <div className={Styles.containerCircles} onClick={capture}>
                    <div className={Styles.outerCircle}>
                        <div className={Styles.innerCircle}>
                            <FontAwesomeIcon icon={faCamera} size="lg" className={Styles.camButton} />
                        </div>
                    </div>
                </div>
                <div className={Styles.instruction}>
                    Selfie com documento ao lado
                </div>
            </div>

        </div>
    );
}

export default Webcam;