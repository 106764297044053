import Button from 'react-bootstrap/Button';
import React, { useEffect, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import { connect } from 'react-redux';
import ParticipanteForm from '../components/ParticipanteForm';
import './index.css';
import votante from '../fetchs/votante';
import { DigitaAprovacaoTokenModal } from '../components/DigitaAprovacaoTokenModal';
import { Link, Redirect } from '../components/RouterPanagora';
import { loginUser, logoutUser } from '../actions';
import { InscricaoSucesso, InscricaoSucessoPrecisaAprovar } from './InscricaoSucesso';
import Layout from './Layout';
import urna from '../fetchs/urna';

function InscricaoIndex(props) {

    const logoutUser = props.logoutUser;
    useEffect(() => {
        logoutUser();
    }, [logoutUser]);

    const [loading, setLoading] = useState(false);
    const [tokenDialog, setTokenDialog] = useState({ show: false, votanteId: null });
    const [mostraSucesso, setMostraSucesso] = useState({ show: false, precisaAprovar: false });
    const [redirectBase, setRedirectBase] = useState(false);
    const [urnas,setUrnas] = useState([]);

    useEffect(() => {
        if (!props.evento.item.id) return;

        urna.fetchList(props.evento.item.id,'inclusao').then(resposta => {
            if (resposta.isSuccess()) {
                setUrnas(resposta.getData());
            } else {
              alert('Erro ao buscar urnas. Tente novamente mais tarde.');
              console.error(resposta);
            }
          });
    },[props.evento.item.id]);


    if (Object.keys(props.evento.item).length === 0) {
        return null;
    }

    if (!props.evento.item.podeCadastrarParticipante) {
        return <Redirect to='/' />
    }





    async function login(nome, tokenTemporario) {
        await props.loginUser(nome, tokenTemporario);
        setRedirectBase(true);
    }


    function handleParticipanteInserted(participante) {
        if (participante.aprovado) {
            onAprovou(participante.nome, participante.tokenTemporario);
        } else {
            if (participante.motivo === 'sms') {
                setTokenDialog({ show: true, votanteId: participante.id });
            } else {
                setMostraSucesso({ show: true, precisaAprovar: true });
            }
        }
    }

    function onAprovou(nome, tokenTemporario) {
        if (props.evento.item.ativo) {
            login(nome, tokenTemporario);
        } else {
            setMostraSucesso({ show: true, precisaAprovar: false });
        }
    }

    function cancelouTokenHandler() {
        setTokenDialog({ show: false });
        setRedirectBase(true);
    }

      

    if (mostraSucesso.show) {
        if (mostraSucesso.precisaAprovar) {
            return (
                <Layout>
                    <InscricaoSucessoPrecisaAprovar />
                </Layout>
            );
        } else {
            return (
                <Layout>
                    <InscricaoSucesso />
                </Layout>
            );
        }
    }

    if (redirectBase) {
        return <Redirect to="/" />
    }

    return (
        <>
            <Layout className="form">
                <Navbar bg="primary" variant="dark" className="mb-4">
                    <Navbar.Brand>{props.evento.item.nome} - Inscrição</Navbar.Brand>
                </Navbar>
                <div className="px-2 pb-4">
                    <ParticipanteForm
                        formName="participanteForm"
                        onParticipanteInserted={handleParticipanteInserted}
                        evento={props.evento.item}
                        postParticipante={(params) => votante.post(props.evento.item.id, params)}
                        onLoadingChange={setLoading}
                        urnas={urnas}
                    />

                    <div className="text-right">

                        <Link to="/auth">
                            <Button variant="secondary">Cancelar</Button>
                        </Link>{' '}
                        <Button type="submit" disabled={loading} form="participanteForm" variant="success">
                            {loading ? "Inscrevendo..." : "Inscrever" }
                        </Button>
                    </div>

                </div>

            </Layout>
            <DigitaAprovacaoTokenModal
                isOpen={tokenDialog.show}
                onHide={cancelouTokenHandler}
                votanteId={tokenDialog.votanteId}
                evento={props.evento.item}
                login={onAprovou}
            />
        </>
    )
}


export default connect(store => ({ evento: store.eventoState.evento }), { loginUser, logoutUser })(InscricaoIndex);


