import React from 'react';
import { matchPath, Link as LinkRouter, Redirect as RedirectRouter } from 'react-router-dom';


function getBase() {

    const usaHostnameDefault = () => {
        return (window.location.hostname === process.env.REACT_APP_HOSTNAME_DEFAULT);
    }

    if (usaHostnameDefault()) {
        const match = matchPath(window.location.pathname, {
            path: "/:sindicatoUrl/:eventoUrl"
        })

        if (match) {
            return `/${match.params.sindicatoUrl}/${match.params.eventoUrl}`;
        }
    } else {
        const match = matchPath(window.location.pathname, {
            path: "/:eventoUrl"
        })

        if (match) {
            return `/${match.params.eventoUrl}`;
        }
    }
    return '';
}

class Link extends React.Component {

    state = {
        base: ''
    }

    componentDidMount = () => {
        this.setState({ base: getBase() })
    }

    render() {

        const to = { pathname: this.state.base + this.props.to };

        return (
            <LinkRouter {...this.props} to={buildLocation(to)}>
                {this.props.children}
            </LinkRouter>
        );
    }
}


class Redirect extends React.Component {

    constructor(props) {
        super(props);
        this.state = { base: getBase() }
    }


    render() {
        const isString = (typeof this.props.to) === "string";
        let to = null;
        const { withBase } = this.props;
        if (isString) {
            const url = new URL(this.props.to,window.location.origin);
            to = { pathname: withBase ? 
                                url.pathname : 
                                this.state.base + url.pathname, 
                    search: url.search };
        } else {
            to = { ...this.props.to, pathname: withBase ? 
                                            this.props.to.pathname :
                                            this.state.base + this.props.to.pathname };
        }

        return (
            <RedirectRouter {...this.props} to={buildLocation(to)} />
        );
    }
}

function buildLocation(to) {
    const params = (new URL(document.location)).searchParams;
    if (params.get('integracaoId')) {
        const urlSearch = new URLSearchParams(to.search);
        urlSearch.set('integracaoId', params.get('integracaoId'));
        to.search = urlSearch.toString();
    }
    return to;
}


export { Link, Redirect, getBase };