import React from 'react'
import Container from 'react-bootstrap/Container';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ConfirmarVoto as ConfirmarVotoPanagora } from '@panagora/front-reactjs-lib'

export default function ConfirmarVoto({ votos, onVoltarClick, onConfirmou }) {
    const usuario = useSelector(state => (state.userState.user));
    const evento = useSelector(state => state.eventoState.evento.item);

    return <Container fluid className="text-center py-3 overflow-auto h-100">
        <ConfirmarVotoPanagora
            votos={votos}
            onVoltarClick={onVoltarClick}
            onConfirmou={onConfirmou}
            urlApiAssembleia={process.env.REACT_APP_URL_API}
            eventoId={evento.id}
            userToken={usuario.token}
        />
    </Container>
}


ConfirmarVoto.propTypes = {
    votos: PropTypes.array.isRequired,
    onVoltarClick: PropTypes.func.isRequired,
    onConfirmou: PropTypes.func.isRequired
}
