import React from "react";
import Container from "react-bootstrap/Container";
import { Redirect } from "../components/RouterPanagora";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { atualizaMeusDados } from "../actions";
import { Termo as PanagoraTermo } from "@panagora/front-reactjs-lib";

export class Termo extends React.Component {
  state = {
    redirectIndex: false,
  };

  concordouHandle = () => {
    this.setState({ redirectIndex: true });
    this.props.atualizaMeusDados(this.props.evento.id, this.props.user.token);
  };

  componentDidMount() {
    this.verificaSeJaNaoConcordou(this.props.meusDados);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.evento !== prevProps.evento ||
      this.props.meusDados !== prevProps.meusDados
    ) {
      this.verificaSeJaNaoConcordou(this.props.meusDados);
    }
  }

  verificaSeJaNaoConcordou = async (meusDados) => {
    if (!meusDados) {
      return;
    }

    if (meusDados.concordouTermo) {
      this.setState({ redirectIndex: true });
    }
  };

  render() {
    if (this.state.redirectIndex) {
      return <Redirect to="/" />;
    }

    return (
      <Container className="overflow-auto h-100" fluid>
        <Container className="h-100" id="termo-container">
          <PanagoraTermo
            urlApiAssembleia={process.env.REACT_APP_URL_API}
            eventoId={this.props.evento.id}
            userToken={this.props.user.token}
            onConcordou={this.concordouHandle}
          />
        </Container>
      </Container>
    );
  }
}

const mapStateToProps = (store) => ({
  evento: store.eventoState.evento.item,
  user: store.userState.user,
  meusDados: store.meusDadosState.meusDados,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ atualizaMeusDados }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Termo);
