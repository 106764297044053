import React from 'react'
import Navbar from 'react-bootstrap/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faEdit } from '@fortawesome/free-solid-svg-icons'
import ListGroup from 'react-bootstrap/ListGroup'

class Perguntas extends React.Component {

    state = {
        loading: true
    };



    handlePerguntaClick = (pergunta) => {
        this.props.onSelecionaPergunta(pergunta);
    }

    render() {

        const listPerguntas = this.props.perguntas.map((pergunta) =>
            <PerguntaListItem
                onClick={this.handlePerguntaClick}
                pergunta={pergunta}
                key={pergunta.seq} 
                active={this.props.selecionada.id === pergunta.id}
                checked={this.props.votos.find((voto)=> voto.perguntaId===pergunta.id)}
                />
        );

        return (
            <div>
                <Navbar bg="light" className="py-1 navbar-2">
                    <Navbar.Brand>ITENS EM PAUTA</Navbar.Brand>
                </Navbar>

                <ListGroup>
                    {listPerguntas}
                </ListGroup>

            </div>
        )
    }
}

function PerguntaListItem(props) {
    return (
        <ListGroup.Item onClick={() => props.onClick(props.pergunta)} 
            className="d-flex p-0 rounded-0 align-items-stretch" action active={props.active}>
            <div className="border-right p-3">
                <FontAwesomeIcon size="2x" className={props.active ? "" : "text-primary"}
                    icon={props.checked ? faCheckCircle : faEdit} />
            </div>
            <div className="font-weight-bold p-3">
                {props.pergunta.titulo}
            </div>
        </ListGroup.Item>
    );
}


export default Perguntas;