import React from 'react';
import EventoParametro from './EventoParametro';
import NotFound from './NotFound'
import { userIsAuthenticated } from './auth'
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import LoginLayout from './LoginLayout';
import PrivateLayout from './PrivateLayout';
import InscricaoIndex from './inscricao';
import { connect } from 'react-redux';
import './index.css';
import './scss/custom.scss';
import LoadCss from './components/LoadCss';
import '@panagora/front-reactjs-lib/dist/index.css'


export function App() {
    return (
        <BrowserRouter>
            <LoadCss>
                <Switch>
                    <Route exact path={["/404", "/:eventoUrl/404", "/:sindicatoUrl/:eventoUrl/404"]} component={NotFound} />
                    <Route exact path={["/:eventoUrl/auth", "/:sindicatoUrl/:eventoUrl/auth"]} component={LoginLayout} />
                    <Route exact path={["/:eventoUrl/inscricao", "/:sindicatoUrl/:eventoUrl/inscricao"]} component={InscricaoIndex} />
                    <Route path="/" component={userIsAuthenticated(PrivateLayout)} />
                </Switch>
            </LoadCss>
            <EventoParametro />
        </BrowserRouter>
    )
}

const mapStateToProps = store => ({
    evento: store.eventoState.evento
});


export default connect(mapStateToProps)(App);