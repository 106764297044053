import React from 'react';
import CpfInput from './form-elements/Cpf';


class Cpf extends React.Component {


  cpf = null;
  state = {
    form: {cpf: ''}
  }

  handleChange = async (event) => {
    let fieldName = event.target.name;
    let fleldVal = event.target.value;
    await this.setState({form: {...this.state.form, [fieldName]: fleldVal}})
  
    this.props.handleFormChange(
      {
        valid:this.valid()
      },
      {
        cpf: this.state.form.cpf && this.state.form.cpf.substr(0,3)+this.state.form.cpf.substr(4,3)+this.state.form.cpf.substr(8,3)+this.state.form.cpf.substr(12,2)
      });
  }

  valid = () => {
    return (this.cpf.valid());
  }

  render() {
    return (
      <>
        <CpfInput value={this.state.form.cpf} onChange={this.handleChange} ref={(ref)=> this.cpf = ref} required/>
      </>
    );    
  }
}

export default Cpf;
