import { matchPath } from 'react-router';
import ResponseModel from './response-model';


function getUrlKey() {
    let eventoUrl = null;
    let sindicatoUrl = null;

    if (window.location.hostname === process.env.REACT_APP_HOSTNAME_DEFAULT) {
        const match = matchPath(window.location.pathname, {
            path: "/:sindicatoUrl/:eventoUrl"
        })
        if (!match) {
            return null;
        }
        eventoUrl = match.params.eventoUrl;
        sindicatoUrl = match.params.sindicatoUrl;
    } else {
        const match = matchPath(window.location.pathname, {
            path: "/:eventoUrl"
        })
        if (!match) {
            return null;
        }
        eventoUrl = match.params.eventoUrl;
        sindicatoUrl = window.location.hostname.split('.')[1];
    }
    return { eventoUrl, sindicatoUrl };
}



async function fetchByUrl() 
{


    try {
        var { sindicatoUrl, eventoUrl } = getUrlKey();
    } catch (error) {
        throw new Error("Url em formato inválido");
    }

    const eventoFetch = await fetch(process.env.REACT_APP_URL_API + '/eventos/parametro/' + sindicatoUrl + '/' + eventoUrl, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        }
    });
    const response = new ResponseModel();    
    return await response.init(eventoFetch);
  
}

const evento = {fetchByUrl, getUrlKey}
export default evento;