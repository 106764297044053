import React from 'react';
import Container from 'react-bootstrap/Container';
import classNames from 'classnames';

function Layout(props) {

    return (
            <div className="overflow-auto h-100">
                <Container className={classNames("px-0",props.className)}>
                    {props.children}
                </Container>
            </div>
    )
}


export default Layout;