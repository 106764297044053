import { getStateUsuario } from "./meusDados";

async function entrar({idEvento}) 
{
  const usuario = getStateUsuario();

  const fet = await fetch(process.env.REACT_APP_URL_API + '/eventos/' + idEvento+'/webinar-entrar', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + usuario.token
    }
  });

  switch (fet.status) {
    case 200:
      return {success:true, status:fet.status,data:await fet.json()};
    case 400:
    case 401:
    case 403:
    case 404:
        return {success:false, status:fet.status,data:await fet.json()};
    default:
      alert('Ops... tente novamente mais tarde');
      console.error(await fet.text());
      break;
  }
  return null; 

}
const webinar = {entrar};

export default webinar;