import React from 'react';
import YouTube from 'react-youtube';
import Card from 'react-bootstrap/Card'

class VideoCard extends React.Component {
  state = {
    titulo: null
  };

  onReady = (event) => {
    this.setState({titulo: event.target.getVideoData().title});
  }

  onEnd = () => {
    if (this.props.onEnd) {
      this.props.onEnd(this.props.videoId);
    }
  }

  render() {
    const opts = {
      width: '100%',
      height: '200px'
    };
    return (
      <Card className="mb-2 shadow-sm rounded">
        <YouTube videoId={this.props.videoId} opts={opts} onEnd={this.onEnd} onReady={this.onReady} />
        <Card.Body>
          <Card.Subtitle className="text-muted">{this.state.titulo}</Card.Subtitle>  
        </Card.Body>
      </Card>

    );
  }
}

export default VideoCard;