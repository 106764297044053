import React from 'react';
import Button from 'react-bootstrap/Button';
import Jumbotron from 'react-bootstrap/Jumbotron';


function Mensagem(props) {
    return (
        <Jumbotron>
            <h3>{props.titulo}</h3>
            <hr/>
            <p>
                {props.children}
            </p>
            <p className="mt-5">
            <Button variant="secondary" className="mr-2" onClick={props.handlerClose}>Tentar mais tarde</Button>
            <Button variant="primary" onClick={props.handlerContinuar}>Continuar</Button>
            </p>
        </Jumbotron>
    );
}

export default Mensagem;