import React, { useState } from "react";
import Alert from "react-bootstrap/Alert";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { postUrna } from "../../fetchs/meusDados";

function ConfirmaUrna({ urna, onVoltar, onSuccess, evento }) {
    const [salvando,setSalvando] = useState(false);

    const salvaUrna = async () => {
        setSalvando(true);
        const returnPostUrna = await postUrna(evento.id, urna.id);

        setSalvando(false);
        if (returnPostUrna.isSuccess()) {
            onSuccess()
        } else {
            console.error(returnPostUrna);
            alert('Ops, ocorreu um erro ao gravar a urna, tente novamente mais tarde.');
        }
    }


    return <>
        <Modal.Body>
            <p>
                Verifique se escolheu a opção correta, pois após confirmar, não será possível alterar
            </p>
            <Alert variant="info" className="text-center text-uppercase">{urna.descricao}</Alert>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onVoltar} disabled={salvando} className="mx-1">
                Voltar
            </Button>
            <Button variant="success" onClick={salvaUrna} disabled={salvando} className="mx-1">
                Confirmar
            </Button>
        </Modal.Footer>

    </>

}

export default ConfirmaUrna;