import React from 'react';
import Alert from 'react-bootstrap/Alert';


export default function ApenasIntegracao() {

    return <div className="w-25 mx-auto text-center mt-3">
        <Alert variant="danger">Não é possível se autenticar a partir desse endereço</Alert>
    </div>;

}