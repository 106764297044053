import React from 'react';
import DoisFa from './DoisFa';
import { connect } from 'react-redux';
import CpfDataNascimentoMatriculaDoisADois from './CpfDataNascimentoMatriculaDoisADois';

export class CpfDataNascimentoMatriculaDoisADois2fa extends React.Component {


  state = {
    form: {cpf: '', dataNascimento: '', matriculaFuncional: ''}
  }

  handleChangeInterno = (valid,data) => {
    this.setState({form: {...this.state.form, ...data}})
    this.props.handleFormChange(valid,data);
  }


  render() {
    return (
      <>
        <DoisFa 
        handleNenhumaDasOpcoes={this.props.handleNaoEncontrado}
        isOpen={this.props.abre}
        onLogin={this.props.onLogin}
        onHide={this.props.handleFechou}
        incluirParticipanteHandle={this.props.incluirParticipanteHandle} 
        params={this.state.form} />
        <CpfDataNascimentoMatriculaDoisADois handleFormChange={this.handleChangeInterno} 
          tituloMatricula={this.props.tituloMatricula} />
      </>
    );    
  }
}


const mapStateToProps = store => ({
  evento: store.eventoState.evento.item
});

export default connect(mapStateToProps)(CpfDataNascimentoMatriculaDoisADois2fa);
