import React, { useCallback, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import QuestionarioForm from './QuestionarioForm';


function QuestionarioFormModal(props) {
    const [loading, setLoading] = useState(false);
    const [erro, setErro] = useState(false);
    const [submiting, setSubmiting] = useState(false);


    useEffect(() => {
        setErro(false);
        setSubmiting(false);
    }, [props.isOpen]);


    const onErro = useCallback(() => {
        setErro(true);
    },[]);

    return (
        <Modal
            onHide={props.handlerClose}
            show={props.isOpen}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Questionário de Autenticação
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <QuestionarioForm data={props.data}
                    onLogin={props.onLogin}
                    handlerClose={props.handlerClose}
                    handlerCadastrar={props.handlerCadastrar}
                    onErro={onErro}
                    onLoading={setLoading}
                    onSubmiting={setSubmiting}
                />

            </Modal.Body>
            {!erro && !loading &&
                (<Modal.Footer>
                    <Button onClick={props.handlerClose} variant="secondary">Cancelar</Button>
                    <Button variant="success" disabled={submiting} type="submit" form="formQuestionario">
                        {submiting ? 'Enviando...' : 'Confirmar'}
                    </Button>
                </Modal.Footer>)
            }
        </Modal>
    );
}
export default QuestionarioFormModal;