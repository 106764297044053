import { logoutUser } from '../actions';
import Store from '../configureStore';
import ResponseModel from './response-model';


async function meusDados(idEvento, token) {

  if (isNaN(idEvento)) {
    return;
  }

  const meusDados = await fetch(process.env.REACT_APP_URL_API + '/eventos/' + idEvento + '/meus-dados', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token
    }
  });

  let meusDadosJson = null;
  let erro = null;
  switch (meusDados.status) {
    case 404:
      meusDadosJson = await meusDados.json();
      alert(meusDadosJson.mensagem);
      console.error(meusDadosJson.mensagem);
      break;
    case 200:
      meusDadosJson = await meusDados.json();
      return meusDadosJson;
    case 401:
      Store.store.dispatch(logoutUser());
      break
    default:
      erro = await meusDados.text();
      alert('Ops... Erro ao recuperar dados, tente novamente mais tarde');
      console.error(erro);
      break;
  }
  return {};

}




async function postUrna(eventoId, urnaId) {

  const url = new URL(process.env.REACT_APP_URL_API + '/eventos/' + eventoId
    + '/meus-dados/urna');


  const usuario = getStateUsuario();

  const fet = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + usuario.token
    },
    body: JSON.stringify({ urna: urnaId })
  });

  const response = new ResponseModel();
  return await response.init(fet);

}


export { meusDados, postUrna }


export function getStateUsuario() {
  const state = Store.store.getState();
  return state.userState.user;
}


export default meusDados;