import { LOGIN_USER, LOGOUT_USER, UPDATE_USER } from "../actions/actionTypes";

const initialState = { user: null };

export const user = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        user: action.user,
      };
    case UPDATE_USER:
        return {
          ...state,
          user: {...state.user, ...action.user},
        };      
    case LOGOUT_USER:
      return {
        ...state,
        user: null,
      };
    default:
      return state;
  }
};
