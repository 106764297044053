import React from 'react';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import ReactInputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import moment from 'moment';


class DataNascimento extends React.Component {

    static propTypes = {
        value: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired
    };

    state = {
        invalid: false,
        valid: false
    }


    handleChange = (event) => {
        const value = event.target.value;
        this.props.onChange(event)


    
        if ((value.indexOf('_') > -1) || (value.length === 0)) {
            this.setState({
                invalid: false,
                valid: false
            });
            return;
        }

    
        const valido = this.valid(value);
        this.setState({
            invalid: !valido,
            valid: valido
        });
    }

    valid = (val) => {
        const value = (val ? val : this.props.value);
        if ((value === '') && (this.props.required === false)) {
            return true;
        }

        return (moment(value,'DD/MM/YYYY',true).isValid()) && 
                (moment(value,'DD/MM/YYYY',true).isBefore(new Date())) &&
                (moment(value,'DD/MM/YYYY',true).isAfter(new Date(1900,1,1)));
    }

    render() {
        return (
            <FormGroup controlId="formDataNascimento">
                <FormLabel>Data de Nascimento</FormLabel>
                <ReactInputMask mask="99/99/9999" value={this.props.value} onChange={this.handleChange}>
                    <FormControl autoComplete="off"
                        isInvalid={this.state.invalid}
                        isValid={this.state.valid}
                        size="lg" name="dataNascimento" required={this.props.required} placeholder="Data de Nascimento"
                        inputMode="numeric"
                    />
                </ReactInputMask>
            </FormGroup>
        );
    }
}

export default DataNascimento;