import React from 'react';
import { atualizaEventoParametro } from './actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from './components/RouterPanagora';
import { Helmet } from "react-helmet";
import { useEffect } from 'react';


function EventoParametro(props) {    

    const atualizaEventoParametro = props.atualizaEventoParametro;
    useEffect(() => {     
        atualizaEventoParametro();
    },[atualizaEventoParametro]);

    if (props.evento.error) {
        return <Redirect to='/404' />
    }
    return (
        <>
            <Helmet>
              <title>{props.evento.item.nome}</title>
                <meta
                    name="description"
                    content={"Panagora: "+props.evento.item.nome}
                />
            </Helmet>
        </>
    );
    
}


const mapStateToProps = store => ({
    evento: store.eventoState.evento
});

const mapDispatchToProps = dispatch =>
    bindActionCreators({ atualizaEventoParametro }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(EventoParametro);

export { EventoParametro } ;