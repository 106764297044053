import React, { useEffect, useState } from 'react';
import votante from '../../fetchs/votante';
import { useSelector } from "react-redux";
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import Pergunta from './Pergunta';
import Mensagem from './Mensagem';


function montaRespostas(perguntas) {
    return perguntas.map((pergunta) => (pergunta.respondida));
}

function MensagemErroGerarQuestionario(props) {
    return <Mensagem
        handlerClose={props.handlerClose}
        handlerContinuar={props.handlerContinuar}
        titulo="Não foi possível gerar o questionário">
        Não conseguimos gerar o seu questionário, você pode se cadastrar ou tentar novamente mais tarde.
    </Mensagem>;
}

function MensagemErroResponderQuestionario(props) {
    return <Mensagem
        handlerClose={props.handlerClose}
        handlerContinuar={props.handlerContinuar}
        titulo="Houve um problema ao responder o questionário">
        Não conseguimos identificar como corretas as suas respostas, tente novamente mais tarde ou se cadastre para participar em separado.
    </Mensagem>;
}

function QuestionarioForm(props) {
    const [loading, setStateLoading] = useState(true);
    const [questionario, setQuestionario] = useState({});
    const [validated, setValidated] = useState(false);
    const [authErro, setAuthErro] = useState(false);
    const evento = useSelector(state => (state.eventoState.evento.item));
    const onLoadingModal = props.onLoading;
    const onErro = props.onErro;
    const [redirectErro,setRedirectErro] = useState(null);

    useEffect(() => {
        function setLoading(bool) {
            setStateLoading(bool);
            onLoadingModal(bool);
        }
        async function fetchQuestionario() {
            setLoading(true);
            try {
                const fetchQuestionario = await votante.fetchQuestionario(evento, props.data);

                if (fetchQuestionario.isSuccess()) {
                    setQuestionario(fetchQuestionario.getData());
                } else {
                    setRedirectErro(fetchQuestionario.getData()['redirect'] ?? null);
                    onErro();
                }                
                setLoading(false);
            } catch (e) {
                onErro();
                console.error(e);
                setLoading(false);
            }
        }
        fetchQuestionario();
    }, [props.data, evento, onLoadingModal, onErro]);

    async function handleSubmit(e) {
        e.preventDefault();
        const form = e.currentTarget;

        if (!form.checkValidity()) {
            setValidated(true);
            return;
        }
        props.onSubmiting(true);


        const autentica = await votante.fetchAutentica(evento, {
            questionarioId: questionario.questionarioId,
            respostas: montaRespostas(questionario.perguntas),
            ...props.data
        });
        props.onSubmiting(false);

        if (autentica.isSuccess()) {
            props.onLogin(autentica.getData().nome, autentica.getData().tokenTemporario);
        } else {
            setRedirectErro(autentica.getData()['redirect'] ?? null);
            setAuthErro(true);
            props.onErro();
            console.warn(autentica.data);
        }



    }

    function handlerCadastrar() {
        props.handlerCadastrar({redirect:redirectErro});
    }

    if (loading) {
        return (
            <div className="text-center">
                <Spinner animation="border" role="status">
                    <span className="sr-only">Carregando...</span>
                </Spinner>

            </div>
        )
    } else if (Object.keys(questionario).length === 0) {
        return <MensagemErroGerarQuestionario
            handlerClose={props.handlerClose}
            handlerContinuar={handlerCadastrar}
        />;
    }

    if (authErro) {
        return <MensagemErroResponderQuestionario
            handlerClose={props.handlerClose}
            handlerContinuar={handlerCadastrar}
        />;
    }

    function responder(pergunta, resposta) {
        questionario.perguntas[pergunta].respondida = resposta;
        setQuestionario({ ...questionario });
    }

    return (
        <Form noValidate onSubmit={handleSubmit} id="formQuestionario" validated={validated}>
            {questionario.perguntas.map((pergunta, i) => (
                <Pergunta
                    key={i}
                    titulo={pergunta.pergunta}
                    id={i}
                    respostas={pergunta.respostas}
                    respondida={pergunta.respondida}
                    onChange={(resposta) => responder(i, resposta)}
                />
            ))}
        </Form>);
}

export default QuestionarioForm;