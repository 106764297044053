import {REQUEST_EVENTO,EVENTO_NAO_ENCONTRADO,RECEIVE_EVENTO, LOGIN_USER, LOGOUT_USER, ATUALIZA_MEUSDADOS, UPDATE_USER} from './actionTypes';
import meusDados from '../fetchs/meusDados';
import evento from '../fetchs/evento';
import jwt_decode from "jwt-decode";


export const loginUser = (nome,token) => ({
  type: LOGIN_USER,
  user: 
  {
    nome:nome,
    token:token,
    papel:jwt_decode(token).papel
  }
});

export const logoutUser = () => ({
  type: LOGOUT_USER
});


export const updateUser = (token) => ({
  type: UPDATE_USER,
  user: {
    token:token,
    papel:jwt_decode(token).papel
  }
});




//redux-thunk
export const atualizaMeusDados = (eventoId,token) => {
 
  return async function(dispatch) {
    const meusDadosJson = await meusDados(eventoId,token);
    dispatch(
      {
        type: ATUALIZA_MEUSDADOS,
        meusDados: meusDadosJson  
      }
    );
  }
  
}


//redux-thunk
export const atualizaEventoParametro = () => {
 
  return async function(dispatch) {
    let response = {};
    dispatch(
      {
        type: REQUEST_EVENTO
      }
    );  
    try {
      response = await evento.fetchByUrl();
      if (!response.isSuccess()) {
        dispatch(
          {
            type: EVENTO_NAO_ENCONTRADO
          }
        );  
      } else {
        dispatch(
          {
            type: RECEIVE_EVENTO,
            evento: response.data 
          }
        );  
      }
    } catch {
      dispatch(
        {
          type: EVENTO_NAO_ENCONTRADO
        }
      );
  
    }
  }
  
}