import React from 'react';
import InformacaoContinuar from '../components/InformacaoContinuar';


function InscricaoSucesso() {

    return (
        <InformacaoContinuar
            titulo="Inscrição Realizada com Sucesso"
            continuarHref="/"
        >
            Volte no início do evento para entrar
        </InformacaoContinuar>
    )
}


function InscricaoSucessoPrecisaAprovar() {

    return (
        <InformacaoContinuar
            titulo="Inscrição Realizada com Sucesso"
            continuarHref="/"
        >
            Sua inscrição precisa ser aprovada para que você possa entrar no evento
        </InformacaoContinuar>
    )
}


export {InscricaoSucesso,InscricaoSucessoPrecisaAprovar};