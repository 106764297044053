import React from 'react';
import Container from 'react-bootstrap/Container';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Redirect, getBase } from '../components/RouterPanagora';
import { Comprovante as ComprovantePanagora } from '@panagora/front-reactjs-lib'
import { bindActionCreators } from 'redux';
import { logoutUser } from '../actions'


export class Comprovante extends React.Component {

    state = {
        redirectVoltar: false,
    };
    
    handleSairClick = () => {
        this.props.history.push(getBase()+'/auth');
        this.props.logoutUser();
    };

    handlerVoltarClick = () => {
        this.setState({ redirectVoltar: true });
    }

    render() {


        if ((!this.props.location.state) || (!this.props.location.state.comprovante)) {
            return (<Redirect to="/" />);

        }
        const { comprovante } = this.props.location.state;

        if (this.state.redirectVoltar) {
            return (<Redirect push to="/" />);
        }

        return (
            <Container fluid className="text-center pt-2 overflow-auto h-100">
                <ComprovantePanagora
                    urlApiAssembleia={process.env.REACT_APP_URL_API}
                    userToken={this.props.user.token}
                    onActionClick={this.handlerVoltarClick}
                    showPrint
                    actionLabel="VOLTAR PARA PAINEL"
                    evento={this.props.evento}
                    dadosComprovante={comprovante}
                    userEmail={this.props.meusDados.email}
                    onLogoutClick={this.handleSairClick}
                    logoutLabel="SAIR"
                />
            </Container>

        );
    }
}

const mapStateToProps = store => ({
    evento: store.eventoState.evento.item,
    user: store.userState.user,
    meusDados: store.meusDadosState.meusDados
});


const mapDispatchToProps = dispatch =>
  bindActionCreators({ logoutUser }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Comprovante));
