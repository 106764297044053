import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Perguntas from './Perguntas';
import PerguntaDescricao from './PerguntaDescricao';
import { PerguntaItens, votar } from '@panagora/front-reactjs-lib'
import BotaoVotar from './BotaoVotar';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Redirect, getBase } from '../components/RouterPanagora';
import meusDados from '../fetchs/meusDados';
import { bindActionCreators } from 'redux';
import { atualizaMeusDados } from '../actions'
import ConfirmarVoto from './ConfirmarVoto';
import SelecionaUrnaModal from '../components/seleciona-urna/SelecionaUrnaModal';

class VotacaoIndex extends React.Component {

    state = {
        perguntaSelecionada: {},
        perguntas: [],
        loading: true,
        comprovanteRedirect: false,
        votos: [],
        showConfirmarVoto: false,
        showSelecionaUrna: false
    };

    componentDidMount() {
        this.fetchPerguntas(this.props.evento.id);
        this.verificaSePodeVotar(this.props.evento.id, this.props.user);

    }

    componentDidUpdate(prevProps) {
        if (this.props.evento !== prevProps.evento) {
            this.fetchPerguntas(this.props.evento.id);
        }
        if ((this.props.user !== prevProps.user) ||
            (this.props.evento !== prevProps.evento)) {
            this.verificaSePodeVotar(this.props.evento.id, this.props.user);
        }
    }

    verificaSePodeVotar = async (eventoId, user) => {
        if (!eventoId)
            return;
        if (!user)
            return;

        const dados = await meusDados(eventoId, user.token);
        if (dados.votou) {
            this.props.history.push(getBase());
        }
    }


    verificaSelecionarUrna = () => {
        if ((!this.props.meusDados.urna) && (this.props.evento.obrigatorioUrna)) {
            this.setState({ showSelecionaUrna: true });
    
        }
    }

    fetchPerguntas = async (idEvento) => {

        if (isNaN(idEvento)) {
            return;
        }

        this.verificaSelecionarUrna();

        this.setState({ loading: true });

        const pergunta = await fetch(process.env.REACT_APP_URL_API + '/eventos/' + idEvento + '/perguntas', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + this.props.user.token
            }
        });

        let perguntaJson = null;
        let erro = null;
        switch (pergunta.status) {
            case 404:
            case 401:
                perguntaJson = await pergunta.json();
                alert(perguntaJson.mensagem);
                console.error(perguntaJson.mensagem);
                break;
            case 400:
            case 403:
                console.debug(await pergunta.text());
                break;
            case 200:
                perguntaJson = await pergunta.json();
                this.setState({ perguntas: perguntaJson });
                this.selecionaPergunta(perguntaJson[0]);
                break;
            default:
                erro = await pergunta.text();
                alert('Ops.. Erro ao recuperar perguntas, tente novamente mais tarde');
                console.error(erro);
                break;
        }

        this.setState({ loading: false });
    }

    selecionaPergunta = (pergunta) => {
        this.setState({ perguntaSelecionada: pergunta })
    }

    getVoto = (pergunta) => {
        let voto = this.state.votos.find(voto => {
            return voto.perguntaId === pergunta.id
        });
        if (voto === undefined) {
            return {}
        }
        return voto;
    }

    votarHandle = (pergunta, itens, itemClicado) => {
        this.setState(
            {
                votos: votar(pergunta,itens,itemClicado,this.state.votos)
            }
        ); 
    }

   

    confirmouVoto = (comprovante) => {
        this.props.atualizaMeusDados(this.props.evento.id, this.props.user.token);
        this.setState({ comprovanteRedirect: true, comprovante, showConfirmarVoto: false });
    }


    confirmarVoto = async () => {
        this.setState({ showConfirmarVoto: true });
    }

    handleSuccessSelecionaUrna = () => {
        this.fetchPerguntas(this.props.evento.id);
        this.setState({showSelecionaUrna:false})
    }

    handleCancelSelecionaUrna = () => {
        this.props.history.push(getBase());
    }

    render() {

        if (this.state.loading) {
            return (
                <Container className="d-flex justify-content-center">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Carregando...</span>
                    </Spinner>
                </Container>
            );
        }

        if (this.state.showConfirmarVoto) {
            return <ConfirmarVoto votos={this.state.votos}
                onConfirmou={this.confirmouVoto}
                onVoltarClick={() => this.setState({ showConfirmarVoto: false })} />
        }

        if (this.state.comprovanteRedirect) {
            return (<Redirect push
                to={{
                    pathname: "/comprovante",
                    state: { comprovante: this.state.comprovante }
                }}
            />);
        }


        const colunaPerguntas = (
            <Col xl={3} lg={4} className="overflow-auto h-100 mb-3 d-none d-xl-block d-lg-block">
                <Perguntas
                    selecionada={this.state.perguntaSelecionada}
                    onSelecionaPergunta={this.selecionaPergunta}
                    perguntas={this.state.perguntas}
                    votos={this.state.votos} />
            </Col>
        );

        const colunaDescricao = (
            <Col xl={this.state.perguntas.length === 1 ? 9 : 6} lg={this.state.perguntas.length === 1 ? 8 : 4} className="border border-top-0 h-100 overflow-auto  mb-3">
                <PerguntaDescricao
                    selecionada={this.state.perguntaSelecionada}
                    onSelecionaPergunta={this.selecionaPergunta}
                    perguntas={this.state.perguntas}
                    votos={this.state.votos}
                />
            </Col>
        );

        const colunaVoto = (
            <Col xl={3} lg={4} className="border border-top-0 h-100 overflow-auto  mb-3">
                <div className="d-flex flex-column h-100 justify-content-between">
                    <PerguntaItens
                        urlApiAssembleia={process.env.REACT_APP_URL_API}
                        eventoId={this.props.evento.id}
                        userToken={this.props.user.token}
                        pergunta={this.state.perguntaSelecionada}
                        onVoto={this.votarHandle}
                        votos={this.state.votos}
                    />

                    <BotaoVotar
                        selecionada={this.state.perguntaSelecionada}
                        onSelecionaPergunta={this.selecionaPergunta}
                        perguntas={this.state.perguntas}
                        votos={this.state.votos}
                        onConfirmarVoto={this.confirmarVoto}
                    />

                </div>

            </Col>
        );
        return (
            <>
                <Container fluid className="h-100 px-0">
                    <Row className="no-gutters h-100">
                        {this.state.perguntas.length > 1 && colunaPerguntas}
                        {colunaDescricao}
                        {colunaVoto}

                    </Row>
                </Container>
                <SelecionaUrnaModal
                    onCancel={this.handleCancelSelecionaUrna}
                    onSuccess={this.handleSuccessSelecionaUrna}
                    open={this.state.showSelecionaUrna}
                />
            </>
        )
    }
}


const mapStateToProps = store => ({
    evento: store.eventoState.evento.item,
    user: store.userState.user,
    meusDados: store.meusDadosState.meusDados
});
const mapDispatchToProps = dispatch =>
    bindActionCreators({ atualizaMeusDados }, dispatch);


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VotacaoIndex));
