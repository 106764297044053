import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Navbar from 'react-bootstrap/Navbar';
import InformacoesPainel from './InformacoesPainel';
import { connect } from 'react-redux';
import parse from 'html-react-parser';
import Spinner from 'react-bootstrap/Spinner';
import { withRouter } from 'react-router-dom';
import { Redirect } from '../components/RouterPanagora';
import { logoutUser } from '../actions';
import { bindActionCreators } from 'redux';
import VideoCollection from './VideoCollection';

export class EventoIndex extends React.Component {

  state = {
    loading: true,
    evento: {},
    redirectVotar: false,
    redirectComprovante: false,
    redirectWebinar: false,
    assistiuVideos: false
  };
  abortController = new AbortController();

  componentDidMount() {
    this.fetchEvento(this.props.evento.item.id);
    this.fetchDescricao(this.props.evento.item.id);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.evento.item !== prevProps.evento.item ||
      this.props.user !== prevProps.user
    ) {
      this.fetchEvento(this.props.evento.item.id);
      this.fetchDescricao(this.props.evento.item.id);
    }
  }
  componentWillUnmount() {
    this.abortController.abort();
  }

  fetchDescricao = async (idEvento) => {
    if (isNaN(idEvento)) {
      return;
    }

    this.setState({ loading: true });

    try {
      const url = `${process.env.REACT_APP_URL_API}/eventos/${idEvento}/descricao`
      const options = {
        signal: this.abortController.signal,
        method: 'GET',
        headers: {
          Accept: 'text/html',
          Authorization: 'Bearer ' + this.props.user.token
        }
      }

      const resposta = await fetch(url, options)
      const descricao = await resposta.text()

      switch (resposta.status) {
        case 404:
          console.error(descricao);
          break;
        case 400:
          console.debug(descricao);
          break;
        case 200:
          this.setState({
            loading: false,
            descricao: descricao,
          });
          break;
        default:
          alert('Ops.. Erro ao recuperar evento, tente novamente mais tarde');
          console.error(descricao);
          this.props.logoutUser();
          break;
      }
    } catch (error) {
      if (error.name !== 'AbortError') {
        throw error;
      }
    }
  }

  fetchEvento = async (idEvento) => {

    if (isNaN(idEvento)) {
      return;
    }
    this.setState({ loading: true });

    try {
      const evento = await fetch(process.env.REACT_APP_URL_API + '/eventos/' + idEvento, {
        signal: this.abortController.signal,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.props.user.token
        }
      });

      let eventoJson = null;
      let erro = null;
      switch (evento.status) {
        case 404:
          eventoJson = await evento.json();
          alert(eventoJson.mensagem);
          console.error(eventoJson.mensagem);
          break;
        case 400:
          console.debug(await evento.text());
          break;
        case 200:
          eventoJson = await evento.json();
          this.setState({ evento: eventoJson, loading: false });
          break;
        default:
          erro = await evento.text();
          alert('Ops.. Erro ao recuperar evento, tente novamente mais tarde');
          console.error(erro);
          this.props.logoutUser();
          break;
      }
    } catch (error) {
      if (error.name !== 'AbortError') {
        throw error;
      }
    }
  }


  handleVotacao = () => {
    if (this.props.meusDados.votou === true) {
      this.setState(
        { redirectComprovante: true }
      );
    } else {
      this.setState(
        { redirectVotar: true }
      );
    }
  }


  handleWebinar = async () => {
    this.setState({ redirectWebinar: true });
  }

  videoEndHandler = () => {
    this.setState({ assistiuVideos: true });
  }

  render() {

    if (this.state.redirectVotar) {
      return (<Redirect push to="/votar" />);
    }

    if (this.state.redirectWebinar) {
      return (<Redirect push to="/webinar" />);
    }


    if (this.state.redirectComprovante) {
      return (<Redirect push
        to={{
          pathname: "/comprovante",
          state: { comprovante: this.props.meusDados }
        }}
      />);
    }

    if (this.state.loading) {
      return (
        <Container className="d-flex justify-content-center">
          <Spinner animation="border" role="status">
            <span className="sr-only">Carregando...</span>
          </Spinner>
        </Container>
      );
    }

    let nInf = 0;
    const renderInformacoesPainel =
      (
        nome,
        dataHoraInicio,
        dataHoraFim,
        onClick,
        segundaAcao = false,
        segundaAcaoNome = '',
        disabled = false,
        disabledText = null) => {
        nInf++;
        return <InformacoesPainel
          cor={`primary${nInf === 1 ? '2' : ''}`}
          nome={nome} className={`mb-${nInf === 1 ? '3' : '1'}`}
          dataHoraInicio={dataHoraInicio}
          dataHoraFim={dataHoraFim}
          segundaAcao={segundaAcao}
          segundaAcaoNome={segundaAcaoNome}
          onClick={onClick}
          disabled={disabled}
          disabledText={disabledText}
        />;
      }

    const temVideo = (this.state.evento.videos) && (this.state.evento.videos.length > 0);
    return (
      <Container fluid className="h-100 pr-lg-0">
        <Row className="no-gutters h-100">
          <Col lg md={12} sm={12} xs={12} className="overflow-auto h-100 mb-3">
            {this.state.descricao && parse(this.state.descricao)}
          </Col>

          {temVideo &&
            (<Col xl={3} lg={4} className="border border-top-0 h-100 overflow-auto  mb-3">
              <Navbar bg="light" className="py-0 navbar-2">
                <Navbar.Brand>MATERIAL DE APOIO</Navbar.Brand>
              </Navbar>
              <div className="px-3">
                <VideoCollection onEnd={this.videoEndHandler} videos={this.state.evento.videos} />
              </div>
            </Col>)}

          {this.state.evento?.temWebinar || this.state.evento?.temVotacao ?
            <Col xl={3} lg={4} md={12} className="border border-top-0 h-100 overflow-auto  mb-3">
              <div className="testeColuna d-flex flex-column justify-content-between h-100">
                {this.state.evento.temWebinar &&
                  renderInformacoesPainel('ASSEMBLEIA', this.state.evento.dataHoraWebinarInicio, this.state.evento.dataHoraWebinarFim, this.handleWebinar)}
                {this.state.evento.temVotacao &&
                  renderInformacoesPainel(
                    'VOTAÇÃO',
                    this.state.evento.dataHoraVotacaoInicio,
                    this.state.evento.dataHoraVotacaoFim,
                    this.handleVotacao,
                    (this.props.meusDados.votou === true),
                    "REEMITIR COMPROVANTE",
                    (this.state.evento.obrigaVisualizacaoVideos &&
                      !this.state.assistiuVideos),
                    "ASSISTA AO VIDEO")}
              </div>
            </Col>
            : ''}
        </Row>


      </Container>
    );
  }
}

const mapStateToProps = store => ({
  evento: store.eventoState.evento,
  user: store.userState.user,
  meusDados: store.meusDadosState.meusDados
});


const mapDispatchToProps = dispatch =>
  bindActionCreators({ logoutUser }, dispatch);


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventoIndex));