import { createStore, applyMiddleware } from 'redux';
import { Reducers } from './reducers';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
//import storageSession from 'redux-persist/lib/storage/session'
import thunk from 'redux-thunk';
import evento from './fetchs/evento';

function getKey() {
  try {
   if (window.location.hostname !== process.env.REACT_APP_HOSTNAME_DEFAULT) {
     return 'root';
   }

    const {sindicatoUrl,eventoUrl} = evento.getUrlKey(); 
    return `${sindicatoUrl}|${eventoUrl}`;
    
  } catch (error) {
    return 'root';
  }
}

const persistConfig = {
  key: getKey(),
  storage: storage,
  whitelist: ['userState']
}

const persistedReducer = persistReducer(persistConfig, Reducers)

const store = createStore(persistedReducer,applyMiddleware(thunk))
const persistor = persistStore(store)

export default {store,persistor};