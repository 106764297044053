import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import FormAlert from './FormAlert';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import Selfie from './participante-form/Selfie';
import classNames from 'classnames';


function DemaisDadosRenderer(props) {
    const field = props.field;
    const fieldUpper = field.charAt(0).toUpperCase() + field.slice(1);
    return (
        <Form.Group controlId={"form" + fieldUpper} className={(props.obrigatorio && 'required')}>
            <Form.Label>{props.nome}</Form.Label>
            {props.opcoes ?
                <Form.Control as="select" disabled={props.readOnly} name={field} required={props.obrigatorio} custom
                    onChange={props.onChange} value={props.value} >
                    <option value={null}></option>
                    {Object.keys(props.opcoes).map((ind) => (<option key={ind} value={ind}>{props.opcoes[ind]}</option>))}
                </Form.Control>
                :
                (props.mascara ?
                    <InputMask mask={props.mascara} disabled={props.readOnly} value={props.value} onChange={props.onChange}>
                        <Form.Control name={field} required={props.obrigatorio} />
                    </InputMask>
                    :
                    <Form.Control name={field} disabled={props.readOnly} required={props.obrigatorio}
                        onChange={props.onChange} value={props.value} />
                )}
        </Form.Group>);
}

class ParticipanteForm extends React.Component {

    static propTypes = {
        evento: PropTypes.object.isRequired,
        onParticipanteInserted: PropTypes.func.isRequired,
        formName: PropTypes.string,
        postParticipante: PropTypes.func,
        participante: PropTypes.object,
        readOnly: PropTypes.bool,
        onLoadingChange: PropTypes.func,
        urnas: PropTypes.array
    };

    edicao = false;

    camposPadrao = ['nome', 'email', 'cpf', 'dataNascimento', 'celular', 'matriculaFuncional', 'urna'];


    constructor(props) {
        super(props);
        this.edicao = (props.participante);

        const valoresDefault = this.carregaValorDefault(props);

        this.state = {
            form: valoresDefault,

            ajaxValidation: {},
            validated: false,
            loading: false
        };

    }

    alert = null;


    carregaValorDefault = (props) => {
        const getValorDefault = (campo) => {
            if (this.edicao) {
                if (this.camposPadrao.find(c => c === campo)) {
                    switch (campo) {
                        case 'dataNascimento':
                            return props.participante[campo] ? 
                                moment(props.participante[campo], 'YYYY-MM-DD').format('DD/MM/YYYY') : '';
                        case 'urna':
                            return (props.participante[campo] ? props.participante[campo].id : '');
                        default:
                            return props.participante[campo] ? props.participante[campo] : '';
                    }
                } else {
                    if (props.participante['demaisDados']) {
                        return props.participante.demaisDados[campo];
                    } else {
                        return '';
                    }
                }
            } else {
                return '';
            }
        }


        let valorDefault = {}
        this.camposPadrao.forEach((campo) => {
            const valor = getValorDefault(campo);
            valorDefault[campo] = (valor === null ? '' : valor);
        });

        if (props.evento) {
            props.evento.camposCustomizaveis.forEach((campo) => {
                const valor = getValorDefault(campo.nome);
                valorDefault[campo.nome] = (valor === null ? '' : valor);
            });
        }

        return valorDefault;
    }

    componentDidUpdate(prevProps) {
        if (this.props.participante !== prevProps.participante) {
            const valoresDefault = this.carregaValorDefault(this.props);
            this.setState({ form: valoresDefault });
        }
    }

    handleSubmit = async (event) => {
        event.preventDefault();

        if (this.props.readOnly) {
            return;
        }

        if (this.state.loading) {
            return;
        }

        const form = event.currentTarget;

        this.setState({
            validated: false,
        });

        if (form.checkValidity() === false) {
            event.stopPropagation();
            this.setState({
                validated: true,
            });
            this.alert.show('Verifique os dados');
            return;
        }

        if (this.props.onLoadingChange) this.props.onLoadingChange(true);
        await this.setState({ loading: true });


        let dataNascimento = null;

        if ((this.state.form.dataNascimento) &&
            (moment(this.state.form.dataNascimento, 'DD/MM/YYYY').isValid)) {
            dataNascimento = moment(this.state.form.dataNascimento, 'DD/MM/YYYY').format('YYYY-MM-DD');
        }

        const demaisDados = {};
        if (this.props.evento) {
            this.props.evento.camposCustomizaveis.forEach(
                (campo) => demaisDados[campo.nome] = this.state.form[campo.nome]);
        }

        const retorno = await this.props.postParticipante({
            nome: this.state.form.nome,
            email: this.state.form.email,
            cpf: this.state.form.cpf,
            dataNascimento: dataNascimento,
            celular: this.state.form.celular,
            matriculaFuncional: this.state.form.matriculaFuncional,
            urna: this.state.form.urna,
            demaisDados: demaisDados,
            fotoFile: this.state.form.fotoFile
        });

        if (this.props.onLoadingChange) this.props.onLoadingChange(false);
        this.setState({ loading: false });
        if (retorno.success) {
            this.props.onParticipanteInserted(retorno.data);
        } else {
            switch (retorno.status) {
                case 400:
                    this.alert.show(retorno.data.mensagem);
                    break;
                case 422:
                    this.setState({ ajaxValidation: retorno.data });
                    this.alert.show('Verifique os dados');
                    break;
                default:
                    break;
            }
        }



    }

    handleChange = (event) => {
        let fieldName = event.target.name;
        let fieldVal = event.target.value;
        this.changeForm(fieldName, fieldVal);
    }

    handleChangeSelfie = (src) => {
        this.changeForm('fotoFile', src);
    }

    changeForm = (fieldName, fieldVal) => {
        const data = { ...this.state.form, [fieldName]: fieldVal };
        this.setState({
            ajaxValidation: { ...this.state.ajaxValidation, [fieldName]: null },
            form: data
        });
        if (this.props.onChange) {
            this.props.onChange(data);
        }
    }



    render = () => {

        const fieldRequired = (nome) => {
            if (this.props.evento) {
                return this.props.evento['obrigatorio' + nome];
            } else {
                return false;
            }
        }
        const cpfDisabled = (this.edicao) && (this.props.participante.cpf);

        return (
            <Form noValidate validated={this.state.validated} id={this.props.formName ? this.props.formName : 'formParticipante'}
                onSubmit={this.handleSubmit}>
                <FormAlert ref={(ref) => this.alert = ref} />

                <Form.Group controlId="formNome" className="required">
                    <Form.Label>Nome</Form.Label>
                    <Form.Control required disabled={this.edicao} isInvalid={this.state.ajaxValidation.nome} name="nome" onChange={this.handleChange} value={this.state.form.nome} maxLength={100} />
                    <Form.Control.Feedback type="invalid">Nome inválido</Form.Control.Feedback>
                </Form.Group>

                <Form.Row>
                    <Form.Group as={Col} sm="6" controlId="formCpf" className={(fieldRequired('Cpf') && 'required')}>
                        <Form.Label>CPF</Form.Label>
                        <InputMask mask="999.999.999-99" disabled={cpfDisabled} value={this.state.form.cpf} onChange={this.handleChange}>
                            <Form.Control name="cpf" required={fieldRequired('Cpf')} isInvalid={this.state.ajaxValidation.cpf} inputMode='numeric' />
                        </InputMask>

                        <Form.Control.Feedback type="invalid">CPF inválido</Form.Control.Feedback>

                    </Form.Group>

                    <Form.Group as={Col} sm="6" controlId="formDataNascimento" className={(fieldRequired('DataNascimento') && 'required')}>
                        <Form.Label>Data de Nascimento</Form.Label>
                        <InputMask mask="99/99/9999" disabled={(this.edicao && this.props.readOnly)} value={this.state.form.dataNascimento} onChange={this.handleChange}>
                            <Form.Control name="dataNascimento" required={fieldRequired('DataNascimento')} isInvalid={this.state.ajaxValidation.dataNascimento} inputMode='numeric' />
                        </InputMask>
                        <Form.Control.Feedback type="invalid">Data de Nascimento inválida</Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>

                <Form.Group controlId="formEmail" className={(fieldRequired('Email') && 'required')}>
                    <Form.Label>Email</Form.Label>
                    <Form.Control required={fieldRequired('Email')} disabled={(this.edicao && this.props.readOnly)} name="email" isInvalid={this.state.ajaxValidation.email} onChange={this.handleChange} value={this.state.form.email} maxLength={100} type="email" placeholder="seunome@provedor.com.br" />
                    <Form.Control.Feedback type="invalid">E-mail inválido</Form.Control.Feedback>
                </Form.Group>

                <Form.Row>
                    <Form.Group as={Col} sm="6" controlId="formCelular" className={(fieldRequired('Celular') && 'required')}>
                        <Form.Label>Celular</Form.Label>
                        <InputMask mask="(99)99999-9999" disabled={(this.edicao && this.props.readOnly)} value={this.state.form.celular} onChange={this.handleChange}>
                            <Form.Control name="celular" inputMode='numeric' required={fieldRequired('Celular')} isInvalid={this.state.ajaxValidation.celular} />
                        </InputMask>
                        <Form.Control.Feedback type="invalid">Celular inválido</Form.Control.Feedback>
                    </Form.Group>
                    {this.props.evento && this.props.evento.tituloMatricula &&
                        <Form.Group as={Col} sm="6" controlId="formMatricula" className={(fieldRequired('Matricula') && 'required')}>
                            <Form.Label>{this.props.evento ? this.props.evento.tituloMatricula : ''}</Form.Label>
                            <Form.Control required={fieldRequired('Matricula')} disabled={(this.edicao && this.props.readOnly)} isInvalid={this.state.ajaxValidation.matriculaFuncional} name="matriculaFuncional" onChange={this.handleChange} value={this.state.form.matriculaFuncional} maxLength={30} />
                            <Form.Control.Feedback type="invalid">Inválido</Form.Control.Feedback>
                        </Form.Group>
                    }
                </Form.Row>
                {this.props.evento && this.props.evento.tituloUrna &&
                    <Form.Group controlId="formUrna" className={(fieldRequired('Urna') && 'required')}>
                        <Form.Label>{this.props.evento ? this.props.evento.tituloUrna : ''}</Form.Label>
                        <Form.Control as="select" disabled={(this.edicao && this.props.readOnly)} name="urna" required={fieldRequired('Urna')} custom
                            onChange={this.handleChange} value={this.state.form.urna} isInvalid={this.state.ajaxValidation.urna} >
                            <option value={null}></option>
                            {this.props.urnas.map((urna) => (<option key={urna.id} value={urna.id}>{urna.descricao}</option>))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">Inválido</Form.Control.Feedback>
                    </Form.Group>
                }
                {this.props.evento ?
                    this.props.evento.camposCustomizaveis
                        .map((campo, i) => (
                            <DemaisDadosRenderer key={i}
                                onChange={this.handleChange}
                                value={this.state.form[campo.nome]}
                                field={campo.nome}
                                nome={campo.titulo}
                                obrigatorio={campo.obrigatorio}
                                mascara={campo.mascara}
                                readOnly={(this.edicao && this.props.readOnly)}
                                opcoes={campo.opcoes} />
                        ))
                    : null}
                {this.props.evento && this.props.evento.incluiFotoVotante &&
                    <div>
                            <div className={classNames({ "is-invalid border border-danger": this.state.ajaxValidation.fotoFile })} >
                                <Selfie onChange={this.handleChangeSelfie} />
                            </div>
                            <div className="invalid-feedback">Sua selfie é obrigatória é deve ter no máximo 20mb de tamanho</div>
                    </div>
                }
            </Form>

        );

    }

}


export default ParticipanteForm;

