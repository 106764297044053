import React from 'react';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { MOTIVO_NAO_APROVADO_SMS, MOTIVO_NAO_APROVADO } from '.';
import { DigitaAprovacaoTokenModal } from '../components/DigitaAprovacaoTokenModal';
import DigitaToken from '../components/DigitaToken';
import votante from '../fetchs/votante';


export async function fetchOpcoes(params, evento) {
  let url = new URL(process.env.REACT_APP_URL_API + '/eventos/' + evento.id + '/autenticar-votante/2fa');
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  let doisFa = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });

  let doisFaJson = await doisFa.json();
  switch (doisFa.status) {
    case 401:
    case 404:
    case 200:
      return { status: doisFa.status, conteudo: doisFaJson };
    default:
      var erro = await doisFa.text();
      console.error(erro);
      alert('Ops.. Erro ao efetuar a autenticação, tente novamente mais tarde');
      return { status: doisFa.status, conteudo: erro };
  }

}


export class Opcoes2fa extends React.Component {

  state = {
    loading: false,
    opcoes: [],
    opcao: {},
    invalido: false,
    abreDigitaTokenAprovacao: false,
    votanteId: null
  }

  opcaoNenhum = {
    votanteId: null,
    tipo: "nenhum",
    descricao: 'Não tenho acesso a nenhuma das opções', 
    valor: null
  }

  componentDidMount() {
    this.fetch(this.props.params);
  }

  componentDidUpdate(prevProps) {
    if ((this.props.confirmou) &&
      (this.props.confirmou !== prevProps.confirmou)) {
      this.handleSubmit();
    }
  }

  fetch = async (params) => {
    this.setState({ loading: true });
    const opcoes = await fetchOpcoes(params, this.props.evento);
    if (opcoes.status === 200) {
      let opcoesAr = opcoes.conteudo.opcoes;
      opcoesAr.push(this.opcaoNenhum);
      this.setState({ loading: false, opcoes: opcoesAr });
    } else {
      this.setState({ loading: false });
      console.error(opcoes.conteudo);
    }
  }


  enviaToken = async (opcao) => {
    const evento = this.props.evento;
    this.setState({ loading: true });
    const doisFa = await fetch(process.env.REACT_APP_URL_API + '/eventos/' + evento.id + '/autenticar-votante/2fa', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ...this.props.params, opcao:opcao.tipo,votanteId:opcao.votanteId })
    });
    this.setState({ loading: false });

    switch (doisFa.status) {
      case 201:
        return true;
      case 400:
        this.tentaAutenticarParaSaberMotivoDoErro(opcao);
        break;
      default:
        var erro = await doisFa.text();
        console.error(erro);
        alert('Ops.. Erro ao efetuar o envio do token, tente novamente mais tarde');
        return false;
    }

  }

  tentaAutenticarParaSaberMotivoDoErro = async (opcao) => {
    const evento = this.props.evento;
    this.setState({
      loading: true
    });
    const autentica = await votante.fetchAutentica(evento, 
      {...this.props.params,votanteId:opcao.votanteId}
    );
    this.setState({
      loading: false
    });

    if (autentica.status === 401) {
      if (autentica.data.motivo === MOTIVO_NAO_APROVADO_SMS) {
        this.setState({abreDigitaTokenAprovacao: true,votanteId:opcao.votanteId});
      } else if (autentica.data.motivo === MOTIVO_NAO_APROVADO) {
        alert('Não aprovado'); // melhorar essa tela, não deveria cair aqui
      } 
    }
    
  }

  setOpcao = (opcao) => {
    this.setState({ opcao: opcao });
  }


  handleSubmit = async () => {
    this.props.onConfirmou();
    if (this.state.opcao.tipo == null) {
      this.setState({
        invalido: true,
      });

      return;
    }
    const opcao = this.state.opcao;
    if (opcao === this.opcaoNenhum) {
      this.props.handleNenhumaDasOpcoes();
    } else {
      if (await this.enviaToken(opcao) === true) {
        this.props.handleEnviou(opcao);
      }  
    }
  }


  fechaDigitaTokenAprovacao = () => {
    this.setState({abreDigitaTokenAprovacao: false});
  }


  render() {

    if (this.state.loading) {
      return (
        <Spinner animation="border" role="status">
          <span className="sr-only">Carregando...</span>
        </Spinner>
      );
    }


    const opcoesCheckRender = this.state.opcoes.map((opcao, i) =>
      (
        <Form.Check
          custom
          className="mt-2"
          key={i}
          type="radio"
          id={`radio-${opcao.votanteId}-${opcao.tipo}`}
          checked={this.state.opcao === opcao}
          onChange={() => this.setOpcao(opcao)}
          label={opcao.descricao + ((opcao.valor !== null) ? `: ${opcao.valor}` : '')}
          isInvalid={this.state.invalido}
        />));

    return (
      <>
        <h6 className="font-weight-bold mb-3">Como deseja receber o token de confirmação?</h6>
        <div>
          {(opcoesCheckRender.length > 0) ?
            opcoesCheckRender :
            (<p>Não existe nenhum meio de receber o token</p>)
          }
        </div>
        <DigitaAprovacaoTokenModal
          isOpen={this.state.abreDigitaTokenAprovacao} 
          onHide={this.fechaDigitaTokenAprovacao} 
          votanteId={this.state.votanteId}
          evento={this.props.evento}
          login={this.props.login}
          incluirParticipanteHandle={this.props.incluirParticipanteHandle}
        />
      </>
    )
  }
}

export class DoisFa extends React.Component {


  state = {
    token: '',
    tokenEnviado: false,
    confirmouClick: false,
    opcaoEnviada: {}
  }

  onShow = () => {
    this.setState({ tokenEnviado: false,confirmouClick:false,token:'' });
  }

  enviouToken = (opcao) => {
    this.setState({opcaoEnviada:opcao, tokenEnviado: true,confirmouClick:false });
  }

  onConfirmou = () => {
    this.setState({confirmouClick:false});
  }

  handleConfirmar = () => {
    this.setState({confirmouClick:true});
  }


  reenviarToken = () => {
    this.setState({ tokenEnviado: false });
  }


  render() {


    let conteudo = null;
    if (!this.state.tokenEnviado) {
      conteudo =
        <Opcoes2fa
          evento={this.props.evento}
          params={this.props.params}
          handleEnviou={this.enviouToken}
          confirmou={this.state.confirmouClick}
          onConfirmou={this.onConfirmou}
          login={this.props.onLogin}
          handleNenhumaDasOpcoes={this.props.handleNenhumaDasOpcoes}
          incluirParticipanteHandle={this.props.incluirParticipanteHandle}
        />

    } else {
      conteudo = 
        <DigitaToken
          fetchSubmit={votante.fetchAutentica}
          reenviarToken={this.reenviarToken} 
          confirmou={this.state.confirmouClick}
          onConfirmou={this.onConfirmou}
          params={this.props.params}
          evento={this.props.evento}
          login={this.props.onLogin}
          mostraContagem={true}
          opcao={this.state.opcaoEnviada}
        />;
    }

    return (
      <Modal
        onHide={this.props.onHide}
        onShow={this.onShow}
        show={this.props.isOpen}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Confirmação de Segurança
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {conteudo}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onHide} variant="secondary">Cancelar</Button>
          <Button onClick={this.handleConfirmar} variant="success">Confirmar</Button>
        </Modal.Footer>
      </Modal>
    );


  }
}

const mapStateToProps = store => ({
  evento: store.eventoState.evento.item
});



export default connect(mapStateToProps)(DoisFa);