import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Redirect } from '../components/RouterPanagora';
import VideoRoom from '@panagora/antmedia-conference-reactjs';
import '@panagora/antmedia-conference-reactjs/dist/index.css'
import Container from 'react-bootstrap/Container';
import Instrucoes from './Panagora/Instrucoes';
import { PollConnector } from '@panagora/video-conferencia-votacao';
import '@panagora/video-conferencia-votacao/dist/index.css';
import { ParticipantAction, QuestaoDeOrdemToolbar, ProviderQuestaoDeOrdem, sortParticipantsHandle } from '@panagora/questao-de-ordem'
import '@panagora/video-conferencia-cronometro/dist/index.css'
import { TimerConnector } from '@panagora/video-conferencia-cronometro'


function Panagora(props) {

    const [ok, setOk] = useState(false);
    const [leave, setLeave] = useState(false);
    const [messageManager, setMessageManager] = useState(null);
    const [userProfile, setUserProfile] = useState('')
    const [otherUsers, setOtherUsers] = useState([])
    const [user, setUser] = useState(null)
    const [usersQuestaoOrdem, setUsersQuestaoOrdem] = useState([])



    function clickHandle() {
        setOk(true);
    }

    function leaveHandler() {
        setLeave(true);
    }



    if (leave) {
        return (<Redirect push to="/" />);
    }


    if (!ok) {
        return (
            <Container className="h-100 overflow-auto text-center pb-5" fluid>
                <Instrucoes />
                <div className="mt-4">
                    <Button variant="primary" onClick={clickHandle}>Continuar</Button>
                </div>
            </Container>
        )
    }

    return (
        <div style={{ position: 'absolute', bottom: '25px', top: '72px', width: '100%' }}>
            <ProviderQuestaoDeOrdem messageManager={messageManager} onSetUsers={setUsersQuestaoOrdem}>
                <VideoRoom
                    token={props.token}
                    urlPublisher={props.urlPublisher}
                    urlPlayer={props.urlPlayer}
                    urlApi={props.urlApi}
                    leaveHandler={leaveHandler}
                    checkConnection={false}
                    onSetUserProfile={(receivedUserProfile) => { setUserProfile(receivedUserProfile) }}
                    onSetOtherUsers={(receivedOtherUsers) => { setOtherUsers(receivedOtherUsers) }}
                    onSetUser={user => { setUser(user) }}
                    setMessageManager={(mm) => { setMessageManager(mm) }}
                    sortParticipantsHandle={users => (sortParticipantsHandle(users, usersQuestaoOrdem))}
                    customParticipantListAction={(user) => (
                        <ParticipantAction user={user} usersQuestaoOrdem={usersQuestaoOrdem} messageManager={messageManager} userProfile={userProfile} />)}
                >
                    <QuestaoDeOrdemToolbar messageManager={messageManager} user={user} />
                    <PollConnector messageManager={messageManager} userProfile={userProfile} otherUsers={otherUsers} />
                    <TimerConnector messageManager={messageManager} userProfile={userProfile} otherUsers={otherUsers} />
                </VideoRoom>
            </ProviderQuestaoDeOrdem>
        </div>
    );

}


export default Panagora;