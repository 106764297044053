import React from 'react';
import Auth from './auth';
import Rodape from './Rodape';
import Logo from './Logo';
import { connect } from 'react-redux';

export function LoginLayout(props) {
    return (
        <div className="overflow-auto h-100" id='login-container-geral'>
            <div className="w-25 my-4 mx-auto text-center" id='login-header-logo'> 
                <Logo />
            </div>
            <div className="p-5 bg-primary d-block text-light text-center text-uppercase font-weight-bolder" id='login-header-title'>
                <h1>{props.evento.item.nome}</h1>
            </div>
            <div id='login-container-body'>
                <Auth className="mt-n4" id='login-body-card'/>
                <Rodape />
            </div>
        </div>
    );
}

const mapStateToProps = store => ({
    evento: store.eventoState.evento
  });
  

export default connect(mapStateToProps)(LoginLayout);

