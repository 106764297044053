import React, { useState } from 'react';
import QuestionarioFormModal from './QuestionarioFormModal';



function Questionario(props) {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState({});

    function handleOpenQuestionario(data) {
        setData(data);
        setOpen(true);
    }

    function handlerCadastrar({redirect}) {
        setOpen(false);
        props.handlerCadastrar({redirect});
    } 


    return (
        <>
            {props.render(handleOpenQuestionario)}
            <QuestionarioFormModal 
                onLogin={props.onLogin}
                isOpen={open} 
                handlerClose={()=>{setOpen(false)}}
                handlerCadastrar={handlerCadastrar}
                data={data} />
        </>
    )

}


export default Questionario;