import React from 'react';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import ReactInputMask from 'react-input-mask';
import { cpf } from 'cpf-cnpj-validator';
import PropTypes from 'prop-types';


class Cpf extends React.Component {

    static propTypes = {
        value: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired
    };

    state = {
        invalid: false,
        valid: false
    }


    handleChangeCpf = (event) => {
        const cpfDig = event.target.value;
        this.props.onChange(event)

        if (cpf.strip(cpfDig).length !== 11) {
            this.setState({
                invalid: false,
                valid: false
            });
            return;
        }
        const valido = this.valid(cpfDig);
        this.setState({
            invalid: !valido,
            valid: valido
        });
    }

    valid = (val) => {
        const value = (val ? val : this.props.value);
        if ((value === '') && (this.props.required === false)) {
            return true;
        }
        return cpf.isValid(value);
    }

    render() {
        return (
            <FormGroup controlId="formCpf">
                <FormLabel>CPF</FormLabel>
                <ReactInputMask mask="999.999.999-99" value={this.props.value} onChange={this.handleChangeCpf}>
                    <FormControl autoComplete="off"
                        isInvalid={this.state.invalid}
                        isValid={this.state.valid}
                        size="lg" name="cpf" required={this.props.required} placeholder="Digite seu CPF"
                        inputMode="numeric"
                    />
                </ReactInputMask>
            </FormGroup>
        );
    }
}

export default Cpf;