import React from 'react';
import { loadCSS as LoadCssFg } from "fg-loadcss"
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';

export class LoadCss extends React.Component {
  state = {
    carregandoCssSindicato: true,
    carregandoCssEvento: true
  };

  componentDidMount(){
    if ((this.props.evento?.item) || (this.props.evento?.error))
      this.loadCss();
  }

  componentDidUpdate(prevProps) {
    if (this.props.evento !== prevProps.evento) 
    this.loadCss();
  }

  loadCss = ()=>{
    if (this.props.evento.error) {
      this.setState({carregandoCssSindicato: false,carregandoCssEvento:false});
      return;
    }

    this.loadCSsSindicato(this.props.evento.item.sindicatoId);
    this.loadCSsEvento(this.props.evento.item.sindicatoId, this.props.evento.item.id);
  }

  loadCSsSindicato = (sindicatoId) => {
    if (!sindicatoId) return;
      let stylesheet = LoadCssFg(`${process.env.REACT_APP_URL_LAYOUT}/sindicato_${sindicatoId}.css`);

      onloadCSS(stylesheet, () => {
        this.setState({carregandoCssSindicato: false})
      });

      stylesheet.onerror = ()=>{
        this.setState({carregandoCssSindicato: false})
      };
  }

  loadCSsEvento = (sindicatoId, eventoId) => {
    if (!sindicatoId || !eventoId) return;
      let stylesheet = LoadCssFg(`${process.env.REACT_APP_URL_LAYOUT}/evento_${eventoId}_sindicato_${sindicatoId}.css`);

      onloadCSS(stylesheet, () => {
        this.setState({carregandoCssEvento: false})
      });
      
      stylesheet.onerror = ()=>{
        this.setState({carregandoCssEvento: false})
      };
  }

  render() {
    return this.state.carregandoCssEvento || this.state.carregandoCssSindicato ?
    <Container className="d-flex justify-content-center" style={{ alignItems: 'center', height: '100vh' }}>
      <Spinner animation="border" role="status">
        <span className="sr-only">Carregando...</span>
      </Spinner>
    </Container>
    :
    <>{this.props.children}</>
  }
}

function onloadCSS(stylesheet, callback) {
  var called;
  function newcb() {
    if (!called && callback) {
      called = true;
      callback.call(stylesheet);
    }
  }
  if (stylesheet.addEventListener) stylesheet.addEventListener("load", newcb);

  if (stylesheet.attachEvent)  stylesheet.attachEvent("onload", newcb);
  
  if ("isApplicationInstalled" in navigator && "onloadcssdefined" in stylesheet) 
  stylesheet.onloadcssdefined(newcb);
}

const mapStateToProps = store => ({
  evento: store.eventoState.evento
});

export default connect(mapStateToProps)(LoadCss);

