import React from 'react';
import Form from 'react-bootstrap/Form';
import moment from 'moment';
import Cpf from './form-elements/Cpf';
import DataNascimento from './form-elements/DataNascimento';


class CpfDataNascimentoMatriculaDoisADois extends React.Component {


  state = {
    form: { cpf: '', dataNascimento: '', matriculaFuncional: '' }
  }

  cpf = null;
  dataNascimento = null;


  handleChange = async (event) => {
    let fieldName = event.target.name;
    let fleldVal = event.target.value;
    await this.setState({ form: { ...this.state.form, [fieldName]: fleldVal } })


    let dataNascimento = null;
    if (moment(this.state.form.dataNascimento, 'DD/MM/YYYY', true).isValid()) {
      dataNascimento = moment(this.state.form.dataNascimento, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }


    this.props.handleFormChange(
      {
        valid: this.valid()
      },
      {
        cpf: this.state.form.cpf && this.state.form.cpf.substr(0, 3) + this.state.form.cpf.substr(4, 3) + this.state.form.cpf.substr(8, 3) + this.state.form.cpf.substr(12, 2),
        dataNascimento: dataNascimento,
        matriculaFuncional: this.state.form.matriculaFuncional
      });
  }

  valid = () => {
    return (this.cpf.valid() && this.dataNascimento.valid());
  }

  render() {

    const cpfVazio = (this.state.form.cpf === "") || (this.state.form.cpf === "___.___.___-__");
    const dataNascimentoVazio = (this.state.form.dataNascimento === "") || (this.state.form.dataNascimento === "__/__/____");
    const matriculaFuncionalVazio = (this.state.form.matriculaFuncional === "");

    const cpfRequired = (dataNascimentoVazio || matriculaFuncionalVazio);
    const dataNascimentoRequired = (cpfVazio || matriculaFuncionalVazio);
    const matriculaFuncionalRequired = (cpfVazio || dataNascimentoVazio);

    return (
      <>
        <Cpf value={this.state.form.cpf} onChange={this.handleChange} ref={(ref) => this.cpf = ref} required={cpfRequired} />
        <DataNascimento value={this.state.form.dataNascimento} onChange={this.handleChange} ref={(ref) => this.dataNascimento = ref} required={dataNascimentoRequired} />

        <Form.Group controlId="formMatriculaFuncional">
          <Form.Label>{this.props.tituloMatricula}</Form.Label>
          <Form.Control autoComplete="off" name="matriculaFuncional" required={matriculaFuncionalRequired} size="lg" onChange={this.handleChange} value={this.state.form.matriculaFuncional} placeholder={this.props.tituloMatricula} />
        </Form.Group>
      </>
    );
  }
}

export default CpfDataNascimentoMatriculaDoisADois;
