import React, { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faClock } from '@fortawesome/free-solid-svg-icons'
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import Countdown from 'react-countdown';
import moment from 'moment';
import useNow from '../components/useNow';


function InformacoesPainel(props) {
  const [clickEnabledState, setClickEnabledState] = useState(false);
  const [clickAntesState, setClickAntesState] = useState(false);
  const now = useNow();


  const setClickEnabled = useCallback(() => {
    let clickEnabled = null;
    let clickAntes = null;

    if (props.segundaAcao) {
      clickEnabled = true;
      clickAntes = false;
    } else {
      clickEnabled = moment(now()).isBetween(props.dataHoraInicio, props.dataHoraFim);
      clickAntes = moment(now()).isBefore(props.dataHoraInicio);
    }
    if (clickEnabled !== clickEnabledState) {
      setClickEnabledState(clickEnabled);
    }
    if (clickAntes !== clickAntesState) {
      setClickAntesState(clickAntes);
    }
  }, [props.dataHoraFim, props.dataHoraInicio, props.segundaAcao, clickEnabledState, clickAntesState, now]);


  useEffect(() => {
    const interval = setInterval(() => {
      setClickEnabled();
    }, 1000);
    setClickEnabled();

    return () => clearInterval(interval);
  }, [setClickEnabled]);


  const rendererCronometro = ({ days, hours, minutes, seconds }) => {
    return (
      <>
        <div>{days.toString().padStart(2, '0')} Dias</div>
        <div>{hours.toString().padStart(2, '0')} Horas</div>
        <div>{minutes.toString().padStart(2, '0')} Min.</div>
        <div>{seconds.toString().padStart(2, '0')} Seg.</div>
      </>
    )
  };

  return (
    <div className={props.className}>
      <Navbar bg={props.cor} variant="dark" className="py-0 navbar-2">
        <Navbar.Brand className="w-100 d-flex flex-row justify-content-between">
          <span className="font-weight-bold">{props.nome}</span>
          <Countdown
            now={now}
            date={moment(props.dataHoraInicio).toDate()}
            renderer={rendererCronometro}
          />
        </Navbar.Brand>
      </Navbar>

      <div className="d-flex flex-column w-75 mx-auto mt-3">
        <span className="my-1 font-weight-bold">INÍCIO</span>
        <div className="d-flex flex-row">
          <div className="border border-left-0 flex-fill p-2">
            <FontAwesomeIcon className="text-muted" icon={faCalendar} />
            {' '}{moment(props.dataHoraInicio).format('DD/MM/YYYY')}
          </div>
          <div className="border-top border-bottom flex-fill p-2">
            <FontAwesomeIcon className="text-muted" icon={faClock} />
            {' '}{moment(props.dataHoraInicio).format('HH:mm:ss')}
          </div>
        </div>
      </div>
      <div className="d-flex flex-column w-75 mx-auto mt-4">
        <span className="my-1 font-weight-bold">FIM</span>
        <div className="d-flex flex-row">
          <div className="border border-left-0 flex-fill p-2">
            <FontAwesomeIcon className="text-muted" icon={faCalendar} />
            {' '}{moment(props.dataHoraFim).format('DD/MM/YYYY')}
          </div>
          <div className="border-top border-bottom flex-fill p-2">
            <FontAwesomeIcon className="text-muted" icon={faClock} />
            {' '}{moment(props.dataHoraFim).format('HH:mm:ss')}
          </div>
        </div>
      </div>

      <div className="px-3 mt-4">
        <Button size="lg" variant={props.cor} disabled={(!clickEnabledState) || (props.disabled)} onClick={props.onClick} block>
          {props.disabled ? props.disabledText :

            props.segundaAcao === true ?
              <>{props.segundaAcaoNome}</> :
              <>{clickEnabledState ? "ACESSAR " + props.nome :
                (clickAntesState ? "AGUARDANDO " + props.nome : "ENCERRADO")}
              </>
          }
        </Button>

      </div>

    </div>
  );

}

export default InformacoesPainel;