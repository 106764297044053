import moment from "moment";
import React from "react";
import { Spinner, Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Countdown from "react-countdown";
import { connect } from "react-redux";
import { atualizaEventoParametro } from "../actions";
import { Link } from "../components/RouterPanagora";
import useNow from "../components/useNow";

function EventoInativoPainel(props) {
  const now = useNow();

  const [loadingTimeout, setLoadingTimeout] = React.useState(false);
  const eventoFuturo = moment(now()).isBefore(props.evento.item.dataHoraEventoInicio);
 
  function onComplete() {
    setLoadingTimeout(true);
    setTimeout(() => {
      setLoadingTimeout(false);
      props.atualizaEventoParametro();
    }, 10000);
  }

  return (
    <div className={`login-form ${props.className}`}>
      <h6>EVENTO FECHADO</h6>

      {eventoFuturo ? (
        <>
          <p className="text-center text-muted mb-4">
            Aguarde o início do evento
          </p>
          <Countdown
            now={now}
            date={moment(props.evento.item.dataHoraEventoInicio).toDate()}
            renderer={cronometroRenderer}
            onComplete={onComplete}
          />
          {props.evento.item.podeCadastrarParticipante && (
            <Link to="/inscricao">
              <Button className="mt-5" size="lg" variant="success" block>
                CADASTRE-SE
              </Button>
            </Link>
          )}
        </>
      ) : (
        <>  
        {!loadingTimeout && 
            <p className="text-center text-danger mb-4">Evento já encerrado</p>
        }
        </>
      )}
        {loadingTimeout && (
            <p style={{textAlign: 'center'}}>
                <Container className="d-flex justify-content-center container mt-2 mb-2">
                    <Spinner animation="border" role="status"></Spinner>
                </Container>
                <span className="">redirecionando para login...</span>
            </p>
            )
        }
    </div>
  );
}

function cronometroRenderer({ days, hours, minutes, seconds }) {
  return (
    <div className="d-flex flex-row justify-content-between">
      <CronometroElemento label="Dias">{days}</CronometroElemento>
      <CronometroElemento label="Horas">{hours}</CronometroElemento>
      <CronometroElemento label="Min.">{minutes}</CronometroElemento>
      <CronometroElemento label="Seg.">{seconds}</CronometroElemento>
    </div>
  );
}

function CronometroElemento(props) {
  return (
    <div className="d-flex flex-column text-center">
      <div className="bg-primary text-light py-2 px-3 font-weight-bolder">
        {props.children.toString().padStart(2, "0")}
      </div>
      <div className="text-muted">
        <small>{props.label}</small>
      </div>
    </div>
  );
}

export default connect((store) => ({ evento: store.eventoState.evento }), {
  atualizaEventoParametro,
})(EventoInativoPainel);
