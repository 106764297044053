import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import votante from '../fetchs/votante';
import { useLocation } from "react-router-dom";
import PropTypes from 'prop-types';
import { updateUser } from '../actions';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Store from '../configureStore';

const PAPEL_VOTANTE_TEMPORARIO = 'votante-temporario';

function GeraTokenContainer({ children }) {
    const usuario = useSelector(state => (state.userState.user));
    const evento = useSelector(state => state.eventoState.evento.item);
    const concordouTermo = useSelector(state => state.meusDadosState.meusDados.concordouTermo);
    
    const location = useLocation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const integracaoId = (new URLSearchParams(location.search)).get("integracaoId");
        if ((Object.keys(evento).length === 0) || (!usuario) || (concordouTermo === undefined)) {
            return;
        }

        if ((usuario.papel !== PAPEL_VOTANTE_TEMPORARIO) && (!integracaoId)) {
            setLoading(false);
            return;
        }
        async function fetchToken() {
            setLoading(true);
            const ret = await votante.fetchToken(evento, { integracaoId });
            if (!ret.isSuccess()) {
                setLoading(false);
                console.debug(ret);
                return;
            }
            if (ret.getData().urlRedirect) {
                Store.persistor.purge();
                window.location.href = ret.getData().urlRedirect;
                return;
            }
            await dispatch(updateUser(ret.getData().token));
            setLoading(false);
        }
        fetchToken();
    }, [evento,location.search, usuario, dispatch, concordouTermo])

    if (loading) {
        return (
            <Container className="d-flex justify-content-center">
                <Spinner animation="border" role="status">
                    <span className="sr-only">Carregando...</span>
                </Spinner>
            </Container>

        );
    }

    return children;
}


GeraTokenContainer.propTypes = {
    children: PropTypes.node
}


export default GeraTokenContainer;