import { ATUALIZA_MEUSDADOS } from '../actions/actionTypes';

const initialState = { meusDados: {} };

export const meusDados = (state = initialState, action) => {
  switch (action.type) {
    case ATUALIZA_MEUSDADOS:
      return {
        ...state,
        meusDados: action.meusDados
      };
    default:
      return state;
  }
};