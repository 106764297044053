import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import ConfirmaUrna from "./ConfirmaUrna";
import SelecionaUrna from "./SelecionaUrna";

function SelecionaUrnaModal({ open, onCancel, onSuccess }) {
    const evento = useSelector(state => (state.eventoState.evento.item));
    const [urna, setUrna] = useState(null);

    useEffect(() => {
        setUrna(null)
    }, [open])


    const handleCancelar = () => {
        onCancel();
    }

    const handleSelecionou = (urna) => {
        setUrna(urna);
    }

    const handleVoltar = () => {
        setUrna(null);
    }

    return (
        <Modal
            onHide={onCancel}
            show={open}
            centered
            backdrop="static"
        >
            {open && <>
                <Modal.Header>
                    <Modal.Title>
                        Seleção de {evento.tituloUrna}
                    </Modal.Title>
                </Modal.Header>
                {urna ?
                    <ConfirmaUrna evento={evento} onVoltar={handleVoltar} onSuccess={onSuccess} urna={urna} /> :
                    <SelecionaUrna evento={evento} onSelecionou={handleSelecionou} onCancelar={handleCancelar} />}
            </>}
        </Modal>
    )


}

export default SelecionaUrnaModal;