import React from 'react';
import Navbar from 'react-bootstrap/Navbar';


export default function Rodape() {
    return (
      <Navbar bg="light" variant="light" fixed="bottom" 
      className="py-0 text-center d-inline-block border-top">
        <Navbar.Text className="py-0" >
        <a href="http://www.panagora.com.br" target="blank">Panagora</a> por Pandora Soluções
        </Navbar.Text>
      </Navbar>
    );
} 