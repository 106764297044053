import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import votante from '../fetchs/votante';
import NovoCadastroLink from './digita-aprovacao-token-modal/NovoCadastroLink';
import DigitaToken from './DigitaToken';
import FormAlert from './FormAlert';

export class DigitaAprovacaoTokenModal extends React.Component {

  state = {
    confirmouClick: false,
    loading: false,
    opcaoEnviada: {}
  }


  componentDidUpdate(prevProps) {
    if ((this.props.isOpen) &&
      (this.props.isOpen !== prevProps.isOpen)) {
      this.reenviarToken();
    }
  }


  reenviarToken = async () => {
    this.setState({ loading: true });
    const envioToken = await votante.fetchEnviarTokenAprovacao(this.props.evento, this.props.votanteId)
    if (!envioToken.isSuccess()) {
      this.alert.show(envioToken.data.mensagem);
    } else {
      this.setState({ opcaoEnviada: envioToken.data });
    }
    this.setState({ loading: false });
  }

  handleConfirmar = () => {
    this.setState({ confirmouClick: true });
  }

  onConfirmou = () => {
    this.setState({ confirmouClick: false });
  }

  onNovoCadastroClick = () => {
    this.props.onHide();
    this.props.incluirParticipanteHandle();
  }

  render() {
    const podeFazerNovoCadastro = this.props.evento.podeVotarSeparado || this.props.evento.podeCadastrarParticipante;
    return (
      <Modal
        onHide={this.props.onHide}
        show={this.props.isOpen}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Confirmação de Segurança
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DigitaToken
            fetchSubmit={votante.fetchAprovar}
            reenviarToken={this.reenviarToken}
            confirmou={this.state.confirmouClick}
            onConfirmou={this.onConfirmou}
            params={{ votanteId: this.props.votanteId }}
            evento={this.props.evento}
            login={this.props.login}
            mostraContagem={false}
            opcao={this.state.opcaoEnviada}
          />
          {podeFazerNovoCadastro &&
            (<NovoCadastroLink incluirParticipanteHandle={
              this.onNovoCadastroClick
            } />)}
          <FormAlert ref={(ref) => this.alert = ref} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onHide} variant="secondary">Cancelar</Button>
          <Button variant="success" disabled={this.state.loading} onClick={this.handleConfirmar}>
            {this.state.loading ? 'Enviando Token…' : 'Confirmar'}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

