import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
import Store from './configureStore';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';


Sentry.init({
  dsn: "https://997474f528c344b69a2b652b35f28813@o994633.ingest.sentry.io/5953239",
  integrations: [
    new Integrations.BrowserTracing(),
    new CaptureConsoleIntegration(
      {
        // array of methods that should be captured
        // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
        levels: ['error']
      })
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,
});




ReactDOM.render(
  <React.StrictMode>
    <Provider store={Store.store}>
      <PersistGate loading={null} persistor={Store.persistor}>
        <App/>
      </PersistGate>    
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

