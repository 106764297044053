import React, { useEffect } from 'react';
import { Link } from '../components/RouterPanagora';
import Button from 'react-bootstrap/Button';

export default function LinkSala(props) {

  useEffect(() => {
    window.open(props.link, '_blank');
  }, [props.link]);



  function redirect() {
    window.open(props.link, '_blank');
  }

  
  return (
    <div className="text-center mt-5">
    <p>
      Estamos tentando te direcionar para a sala de transmissão,
      caso isso não ocorra em alguns segundos, clique no botão a baixo
    </p>
    <p>
      <Button size="lg" onClick={redirect}>Entrar na Sala de Transmissão</Button><br/>
      ou <Link to="/">clique aqui</Link> para voltar
    </p>
  </div>

  );
}