import React from 'react';
import Image from 'react-bootstrap/Image'
import logo from "./logo.svg"
import { connect } from 'react-redux';

export class Logo extends React.Component {


    state = {
        image: ""
    }
    componentDidUpdate(prevProps) {
        if (this.props.evento !== prevProps.evento) {
            this.loadLogo(this.props.evento.item.sindicatoId);
        }
    }

    componentDidMount() {
        this.loadLogo(this.props.evento.item.sindicatoId);
    }

    loadLogo = async (sindicatoId) => {
        if (!sindicatoId)
            return;
  
        let resSvg = null;
        let resPng = null;
            
        await Promise.all([
            resSvg = await fetch(process.env.REACT_APP_URL_LAYOUT + "/logo_" + sindicatoId + ".svg"),
            resPng = await fetch(process.env.REACT_APP_URL_LAYOUT + "/logo_" + sindicatoId + ".png")
        ]);
        
        if (resSvg.status === 200) {
            const img = await resSvg.blob();            
            this.setState({image:URL.createObjectURL(img)});
            return;
        }

        if (resPng.status === 200) {
            const img = await resPng.blob();   
            this.setState({image:URL.createObjectURL(img)});
            return;
        }
            
    }


    render() {
        if (this.state.image) {
            return (
                <Image src={this.state.image} fluid/>
            );    
        } else {
            return (
                <Image src={logo} fluid/>
            );    
        }

    }
}


const mapStateToProps = store => ({
    evento: store.eventoState.evento
});



export default connect(mapStateToProps)(Logo);

