import React from 'react';
import VideoCard from './VideoCard';

class VideoCollection extends React.Component {
  state = {
  };
  videosAssistidos = [];

  videoEndHandler = (videoId) => {
    if (!this.videosAssistidos.includes(videoId)) {
      this.videosAssistidos.push(videoId);
    }
    if (this.props.videos.length === this.videosAssistidos.length) {
      this.props.onEnd();
    }
  }
  render() {
    return (
      <>
      {this.props.videos.map(
        (videoId, i) => 
        <VideoCard onEnd={this.videoEndHandler} key={i} videoId={videoId} />)
      }
      </>

    );
  }
}

export default VideoCollection;